import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import jsCookie from 'js-cookie';

import i18n from "i18next";



import { useLocation } from 'react-router-dom'



const Header = () => {

	const { t } = useTranslation();
	const location = useLocation();

	const languages = [
		{
			code: 'fr',
			name: 'Français',
			country_code: 'fr'
		},
		{
			code: 'en',
			name: 'Anglais',
			country_code: 'gb'
		}
	]
	
	const current_url=location.pathname

	let getActiveStringTab = function (params) {
		const splitLocation = pathname.split("/");
		const ifactive  = "";
		if (params == splitLocation[1]) {
			return "nav-link py-4 px-6 active"

		}
		else{
			return "nav-link py-4 px-6 "
		}
	
	}
	

	

	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

	/*const [jquery, jqueryEroor] = useScript({
		src: 'js/jquery-3.6.0.min.js',
		checkForExisting: true,
	});*/

/*const [bundle15aa, errorbundle] = useScript({
		src: 'metronic/plugins/global/plugins.bundle15aa.js?v=7.2.2',
		checkForExisting: true,
	});

	
		
	const [prismjs, errorprism] = useScript({
		src: 'metronic/plugins/custom/prismjs/prismjs.bundle15aa.js?v=7.2.2',
		checkForExisting: true,
	});



	


	
	const [scriptsBundle, errorplugin] = useScript({
		src: 'metronic/js/scripts.bundle15aa.js?v=7.2.2',
		checkForExisting: true,
	});

				
	const [widgets15aa, errorwidget] = useScript({
		src: 'metronic/js/pages/widgets15aa.js',
		checkForExisting: true,
	});
	*/

	

		
	/*const [loginGeneral15aa, errorloginGeneral15aa] = useScript({
		src: 'metronic/js/pages/custom/login/login-general15aa.js?v=7.2.2',
		checkForExisting: true,
	});*/


    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

	console.log(splitLocation)

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect((e) => {
		if (isLoaded) {

			
			//history.push("/dashboard")
			setIsPageLoaded(true);
		}
	}, [isLoaded]);



	

	const currentLanguageCode = jsCookie.get('i18next') || 'fr';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);



	return (

		<div>
			
	
			<div id="kt_header_mobile" class="header-mobile bg-primary header-mobile-fixed">
				<Link to="index.html">
					<img src="logo2.png" class="max-h-40px" alt="" />

				</Link>
				<div class="d-flex align-items-center">
					<button class="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
						<span></span>
					</button>
					<button class="btn p-0 ml-2" id="kt_header_mobile_topbar_toggle">
						<span class="svg-icon svg-icon-xl">
							<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<polygon points="0 0 24 0 24 24 0 24" />
									<path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
									<path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
								</g>
							</svg>
						</span>
					</button>
				</div>
			</div>

			<div class="d-flex flex-column flex-root">
				<div class="d-flex flex-row flex-column-fluid page">
					<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

						<div class="d-flex flex-column flex-root">
							<div class="d-flex flex-row flex-column-fluid page">
								<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">


									<div id="kt_header" class="header flex-column header-fixed">
										<div class="header-top">
											<div class="container">
												<div class="d-none d-lg-flex align-items-center mr-3">
													<Link to="index.html" class="mr-20">
														<img src="logo2.png" class="max-h-40px" alt="" />
													</Link>
													<ul class="header-tabs nav align-self-end font-size-lg" role="tablist">
														<li class="nav-item" >
															<Link to="/dashboard"  className={splitLocation[1] === "dashboard" ? "nav-link py-4 px-6  active" : "nav-link py-4 px-6 "} data-toggle="tab" data-target="#tabdashboard" role="tab">Tableau de bord</Link>
														</li>
														<li class="nav-item" >
															<Link to="/menufrontoff"  className={splitLocation[1] === "menufrontoff" ? "nav-link py-4 px-6  active" : "nav-link py-4 px-6 "} data-toggle="tab" data-target="#taboperations" role="tab">Opérations</Link>
														</li>
														<li class="nav-item mr-3" >
															<Link to="#" class="nav-link py-4 px-6" data-toggle="tab" data-target="#kt_header_tab_2" role="tab">Rapports</Link>
														</li>
														<li class="nav-item mr-3" >
															<Link to="/users" className={!((splitLocation[1] != "agences")&&(splitLocation[1] != "add_agence")&&(splitLocation[1] != "changepassword")&&(splitLocation[1] != "permissionprofile")&&(splitLocation[1] != "accountinfo")&&(splitLocation[1] != "personnalinfo")&&(splitLocation[1] != "users")&&(splitLocation[1] != "userslist")&&(splitLocation[1] != "adduser")&&(splitLocation[1] != "listrole")&&(splitLocation[1] != "listpermission"))  ? "nav-link py-4 px-6 active" : "nav-link py-4 px-6 "} data-toggle="tab" data-target="#tabconfiguration" role="tab">Configurations </Link>
														</li>

													</ul>
												</div>
												<div class="topbar bg-primary">
													<div class="dropdown">
														<div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
															<div class="btn-group dropright">
																<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																	<span className='fa fa-globe'></span> <b>{t("change_language_text")}</b></button>
																<div className="dropdown-menu " >
																	{languages.map(({ code, name, country_code }) => (
																		<a key={country_code} class="dropdown-item switch-locale" data-locale={code} >
																			<button className='btn' onClick={() => i18n.changeLanguage(code)}>
																				<span className={'flag-icon flag-icon-' + country_code + ' mx-2'}></span>
																				{name}

																			</button>

																		</a>

																	))}


																</div>
															</div>

														</div>


													</div>




													<div class="topbar-item">
														<div class="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
															<div class="d-flex flex-column text-right pr-sm-3">
																<span class="text-white opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">Nom d'Utilisateur</span>
																<span class="text-white font-weight-bolder font-size-sm d-none d-sm-inline">Rôle</span>
															</div>
															<span class="symbol symbol-35">
																<span class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">S</span>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="header-bottom">
											<div class="container">
												<div class="header-navs header-navs-left" id="kt_header_navs">
													<ul class="header-tabs p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs" role="tablist">

														<li class="nav-item mr-2" >
															<Link to="#" class="nav-link btn btn-clean " data-toggle="tab" data-target="#tabdashboard" role="tab">Tableau de bord</Link>
														</li>


														<li class="nav-item mr-2" >
															<Link to="#" class="nav-link btn btn-clean" data-toggle="tab" data-target="#taboperations" role="tab">Opérations</Link>
														</li>


														<li class="nav-item mr-2">
															<Link to="#" class="nav-link btn btn-clean" data-toggle="tab" data-target="#kt_header_tab_2" role="tab">Rapport</Link>
														</li>
														<li className={!((splitLocation[1] != "agences")&&(splitLocation[1] != "add_agence")&&(splitLocation[1] != "personnalinfo")&&(splitLocation[1] != "users")&&(splitLocation[1] != "userslist")&&(splitLocation[1] != "adduser")&&(splitLocation[1] != "detailuser")&&(splitLocation[1] != "listrole")&&(splitLocation[1] != "listpermission")) ? "nav-item mr-2 active" : "nav-item mr-2"}>
															<Link to="#" class="nav-link btn btn-clean" data-toggle="tab" data-target="#tabconfiguration" role="tab">Configuration</Link>
														</li>
													</ul>
													<div class="tab-content">
														<div class="tab-pane py-5 p-lg-0  " id="tabdashboard">
															<div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
																<ul class="menu-nav">
																
																	<li className={splitLocation[1] === "dashboard" ? "menu-item menu-item-active" : "menu-item"} aria-haspopup="true">
																		<div href="#" class="menu-link">
																			<Link to="/dashboard"><span class="menu-text">Géneral</span></Link>
																		</div>
																	</li>

																	<li class="menu-item " aria-haspopup="true">
																		<div class="menu-link">

																		</div>
																	</li>
																</ul>

															</div>

														</div>
														<div class="tab-pane py-5 p-lg-0  " id="tabconfiguration">
															<div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
																<ul class="menu-nav">

																	<li className={!((splitLocation[1] != "agences")&&(splitLocation[1] != "add_agence")&&(splitLocation[1] != "changepassword")&&(splitLocation[1] != "permissionprofile")&&(splitLocation[1] != "accountinfo")&&(splitLocation[1] != "personnalinfo")&&(splitLocation[1] != "users")&&(splitLocation[1] != "userslist")&&(splitLocation[1] != "adduser")&&(splitLocation[1] != "detailuser")&&(splitLocation[1] != "listrole")&&(splitLocation[1] != "listpermission")) ? "menu-item menu-item-active" : "menu-item"}  aria-haspopup="true">
																		<div class="menu-link">
																			<Link to="/users" data-toggle="tab" data-target="#tabconfiguration" role="tab"><span class="menu-text">Gestion des utilisateurs</span></Link>
																		</div>
																	</li>
																	<li class="menu-item" aria-haspopup="true">
																		<div href="#" class="menu-link">
																			<Link to="#"><span class="menu-text">Comptes  </span></Link>
																		</div>
																	</li>
																	<li class="menu-item" aria-haspopup="true">
																		<div href="#" class="menu-link">
																			<Link to="#"><span class="menu-text">Caisse </span></Link>
																		</div>
																	</li>
																	<li class="menu-item" aria-haspopup="true">
																		<div href="#" class="menu-link">
																			<Link to="#"><span class="menu-text">Commissions </span></Link>
																		</div>
																	</li>


																</ul>

															</div>

														</div>


														<div class="tab-pane py-5 p-lg-0  active" id="taboperations">
															<div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
																<ul class="menu-nav">

																	<li className={splitLocation[1] === "menufrontoff" ? "menu-item menu-item-active" : "menu-item"}  aria-haspopup="true">
																		<div  class="menu-link">
																			<Link to="/menufrontoff"><span class="menu-text">Front Office</span></Link>
																		</div>
																	</li>
																	<li class="menu-item" aria-haspopup="true">
																		<div href="#" class="menu-link">
																			<Link to="#"><span class="menu-text">Back Office </span></Link>
																		</div>
																	</li>
																	<li class="menu-item" aria-haspopup="true">
																		<div href="#" class="menu-link">
																			<Link to="#"><span class="menu-text">Money Cash </span></Link>
																		</div>
																	</li>


																</ul>

															</div>

														</div>


														<div class="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
															<div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">

																<Link to="#" class="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0">Rcemment publiés</Link>
																<Link to="#" class="btn btn-light-primary font-weight-bold my-2 my-lg-0">Service personnalisé</Link>

															</div>
															<div class="d-flex align-items-center">

																<Link to="#" class="btn btn-danger font-weight-bold my-2 my-lg-0">Générer les rapports</Link>

															</div>
														</div>

													</div>

												</div>

											</div>

										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>


	)
}

export default Header