
import React, { Component } from 'react';



import ScriptImport from '../layout/ScriptImport';

import FormContent from './FormContent';
import TextJsInteraction from './TextJsInteraction';
import { Helmet } from 'react-helmet';
import RemoveScript from '../layout/RemoveSrc';



import $ from 'jquery'; 






//
class Auth extends Component {

    constructor(props) {
        super(props);
        this.state = {show: true};
      }

 
      componentDidMount() {
        {
             ScriptImport("metronic/js/scripts.bundle15aa.js?v=7.2.2");


           /* if (!document.getElementsByClassName("script_import")) {

            

                //ScriptImport("js/jquery-3.6.0.min.js");
                ScriptImport("js/myscript.js");
                ScriptImport("metronic/plugins/global/plugins.bundle15aa.js?v=7.2.2");
                ScriptImport("metronic/plugins/custom/prismjs/prismjs.bundle15aa.js?v=7.2.2");
               

                ScriptImport("metronic/js/pages/widgets15aa.js");
            }
            */

            window.addEventListener('load', this.handleLoad);

        }


    }

    componentDidUpdate() {
        {


        }
    }
    componentWillUnmount() {


        {
         
            window.removeEventListener('load', this.handleLoad)


        }
    }
render() {
       
          
      

        return (
            <div>
                  <FormContent />
                  
            </div>
          
            

        );
    }


}



export default Auth