import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

import swal from 'sweetalert';
import useScript from 'react-script-hook';
import AuthContext from '../../context/AuthProvider';
import axios from 'axios';





const SignIn = () => {
	const { t } = useTranslation();



	let history = useHistory();



	const [formData, setFormData] = useState({
		username: "",
		password: "",
		page_opt: false

	})

	const handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		formData[name] = value;
		setFormData(formData);
	};

	const { register, handleSubmit, formState: { errors } } = useForm();
	//const handleError = (errors) => { };

	const signinOptions = {
		username: { required: t("notif_champ_username") },
		password: { required: t("notif_password") },
		/*password: {
			required: "Password is required",
			minLength: {
				value: 8,
				message: "Password must have at least 8 characters"
			}
		}*/
	};



	/*handleChange = handleChange.bind(useState)
	onSubmitForm = onSubmitForm.bind(u)*/

	/*const [bundle15aa, errorbundle] = useScript({
		src: 'metronic/plugins/global/plugins.bundle15aa.js?v=7.2.2',
		checkForExisting: true,
	});

	
		
	const [prismjs, errorprism] = useScript({
		src: 'metronic/plugins/custom/prismjs/prismjs.bundle15aa.js?v=7.2.2',
		checkForExisting: true,
	});



	


	
	const [scriptsBundle, errorplugin] = useScript({
		src: 'metronic/js/scripts.bundle15aa.js?v=7.2.2',
		checkForExisting: true,
	});

				
	const [widgets15aa, errorwidget] = useScript({
		src: 'metronic/js/pages/widgets15aa.js',
		checkForExisting: true,
	});*/



	let handleSubmitForm = async () => {
		history.push("/otp")

		/*swal({
			title: "Un code a été envoyé sur votre compte ",
			text: "Confirmation du login ",
			icon: "success",
			button: "ok",
		  });*/

		/*await axios.get("api/login/firstLogin", { params: { login: formData.username, passWord: formData.password } })
			.then(response => {
				return response.data;
			})
			.then(response => {
				if (response.length > 0) {
					console.log(response); 
					localStorage.setItem('user', this.state.form.username);
					localStorage.setItem('pass', this.state.form.password);

					//history.push("/otp")

				} else {
					swal({
						title: "Mot de passe incorrect ",
						text: "Confirmation du login ",
						icon: "error",
						button: "Valider",
					});
				}
			})
			.catch(error => {
				console.log(error);
			})*/


		return false

	}

	return (
		<div>

			<div class="text-center mb-10 mb-lg-20">
				<br></br>		<br></br>	<br></br>	<br></br>
				<img src="../../../../../logonew.png" class="max-h-100px" alt="" />
				<br />

			</div>

			<h1><b>Connexion</b></h1>
			<form class="form" novalidate="novalidate" id="kt_login_signin_form" onSubmit={handleSubmit(handleSubmitForm)}>
				<div class="form-group">
					<input class="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder={t("plh_username")} id="username" name='username' onChange={handleChange} {...register('username', signinOptions.username)} autocomplete="off" />
					<small className="text-danger">
						{errors?.username && errors.username.message}
					</small>
				</div>

				<div class="form-group">
					<input class="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder={t('plh_password')} id="password" name="password" onChange={handleChange}  {...register('password', signinOptions.password)} autocomplete="off" />
					<small className="text-danger">
						{errors?.password && errors.password.message}
					</small>
				</div>
				<div class="form-group d-flex flex-wrap justify-content-between align-items-center">
					<Link to="/forgotpassword" class="text-dark-50 text-hover-primary my-3 mr-2" >{t("forgot_password_text")}</Link>
					<button type="submit" id="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3">{t("btn_login")}</button>
				</div>
			</form>
			<br></br>	<br></br>	<br></br>	<br></br>	<br></br>	

		</div>
	)
}


export default SignIn