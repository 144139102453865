import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";
import { useHistory } from 'react-router-dom';




const SectionMenuConfig = () => {
	const { t } = useTranslation();


	let history = useHistory();

	const languages = [
		{
			code: 'fr',
			name: 'Français',
			country_code: 'fr'
		},
		{
			code: 'en',
			name: 'Anglais',
			country_code: 'gb'
		}
	]




	const currentLanguageCode = jsCookie.get('i18next') || 'fr';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);



	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps



	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect((e) => {
		if (isLoaded) {

			history.push("/users")
			setIsPageLoaded(true);
		}
	}, [isLoaded]);






	return (
		<div>
			
			<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div class="d-flex flex-column-fluid">
					<div class="container">
						<div class="row"><br />
							<h5 class="text-dark font-weight-bold my-1 mr-5">Gestion des utilisateurs </h5>

							<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
								<li class="breadcrumb-item text-muted">
									<Link to="" href="#" class="text-muted">Menu général</Link>
								</li>

							</ul>
						</div><br /><br />
						<div class="row">
							<div class="col-xl-6">
								<div class="row m-0">
									<div class="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
										<center>	<Link to="/userslist" data-toggle="tab" data-target="#tabconfiguration" class="text-primary font-weight-bold font-size-h6">

											<i class="flaticon2-avatar text-primary" style={{ fontSize: '80px' }}></i><br />
										Utilisateurs</Link>
										</center>

									</div>
									<div class="col bg-white px-6 py-8 rounded-xl mb-7">

										<center>

										<Link  to="/listrole" data-toggle="tab" data-target="#tabconfiguration"  class="text-primary font-weight-bold font-size-h6">
										<i class="flaticon2-user-1 text-primary" style={{ fontSize: '80px' }}></i><br />
											Rôles</Link>
										</center>
									</div>

								</div>

							</div>
							<div class="col-xl-6">

							<div class="row m-0">
									<div class="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
										<center>
										<Link  to="/listpermission" data-toggle="tab" data-target="#tabconfiguration"  class="text-primary font-weight-bold font-size-h6">
											
											<i class="flaticon-safe-shield-protection text-primary" style={{ fontSize: '80px' }}></i><br />
											Permissions</Link>
										</center>

									</div>
									<div class="col bg-white px-6 py-8 rounded-xl mb-7">

										<center>
										<Link to="/agences" data-toggle="tab" data-target="#tabconfiguration"  class="text-primary font-weight-bold font-size-h6">
											<i class="flaticon2-architecture-and-city text-primary" style={{ fontSize: '80px' }}></i><br />
											Agences</Link>
										</center>
									</div>

								</div>

							</div>

						</div>

					
					<br /><br /><br /><br /><br /><br /><br /><br />

					</div>
				</div>
			</div>

		</div>

	)
}


export default SectionMenuConfig