
import React, { Component } from 'react';



import ForgotConfirmContent from './ForgotConfirmContent';

import $ from 'jquery'; 






//
class ForgotConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {show: true};
      }

    componentWillUnmount() {
        {
          

        }
        

    }

    componentDidUpdate(){
       
        

    }    
    componentDidUnmount () {
       
     
    }
    render() {
       
          
      

        return (
            <div>
                  <ForgotConfirmContent/>
                  
            </div>
          
            

        );
    }


}



export default ForgotConfirm