import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";
import { useHistory } from 'react-router-dom';

import { useLocation } from 'react-router-dom'; 

import General from '../General';





const SectionChangePassword = () => {
	const { t } = useTranslation();


	let history = useHistory();

	const languages = [
		{
			code: 'fr',
			name: 'Français',
			country_code: 'fr'
		},
		{
			code: 'en',
			name: 'Anglais',
			country_code: 'gb'
		}
	]




	const currentLanguageCode = jsCookie.get('i18next') || 'fr';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);



	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps


	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect((e) => {
		if (isLoaded) {

			//history.push("/users")
			setIsPageLoaded(true);
		}
	}, [isLoaded]);

	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");




	return (
		<div>

			<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div class="d-flex flex-column-fluid">
					<div class="container">
						<div class="row"><br />
							<h5 class="text-dark font-weight-bold my-1 mr-5">Gestion des utilisateurs</h5>

							<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
								<li class="breadcrumb-item text-muted">
									<Link class="text-muted" to="/users" data-toggle="tab" data-target="#tabconfiguration" role="tab">Menu général</Link>
								</li>
								<li class="breadcrumb-item text-muted">
									<Link to="/userslist" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Liste des utilisateurs </Link>
								</li>

								<li class="breadcrumb-item text-muted">
									<Link to="/adduser" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted" >Profil de l'utilisateur</Link>
								</li>

							</ul>
						</div>
						<div class="content d-flex flex-column flex-column-fluid" id="kt_content">

							<div class="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
								<div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

									<div class="d-flex align-items-center flex-wrap mr-1">

										<button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
											<span></span>
										</button>

										<div class="d-flex align-items-baseline flex-wrap mr-5">

											<h5 class="text-dark font-weight-bold my-1 mr-5">Profil de l'utilisateur </h5>

											

										</div>

									</div>

									<div class="d-flex align-items-center">

										<a href="#" class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>

										<div class="dropdown dropdown-inline" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
											<a href="#" class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span class="svg-icon svg-icon-success svg-icon-2x">
													<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<polygon points="0 0 24 0 24 24 0 24"></polygon>
															<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
															<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000"></path>
														</g>
													</svg>

												</span>
											</a>
											<div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">

												<ul class="navi navi-hover">
													<li class="navi-header font-weight-bold py-4">
														<span class="font-size-lg">Choose Label:</span>
														<i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="" data-original-title="Click to learn more..."></i>
													</li>
													<li class="navi-separator mb-3 opacity-70"></li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-success">Customer</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-danger">Partner</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-warning">Suplier</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-primary">Member</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-dark">Staff</span>
															</span>
														</a>
													</li>
													<li class="navi-separator mt-3 opacity-70"></li>
													<li class="navi-footer py-4">
														<a class="btn btn-clean font-weight-bold btn-sm" href="#">
															<i class="ki ki-plus icon-sm"></i>Add new</a>
													</li>
												</ul>

											</div>
										</div>

									</div>

								</div>
							</div>

							<div class="d-flex flex-column-fluid">

								<div class="container">

									<div class="d-flex flex-row">

										<div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
											<General />

										
										</div>

										<div class="flex-row-fluid ml-lg-8">
										
										<div class="card card-custom">
											
											<div class="card-header py-3">
												<div class="card-title align-items-start flex-column">
													<h3 class="card-label font-weight-bolder text-dark">Changement du mot de passe</h3>
													<span class="text-muted font-weight-bold font-size-sm mt-1">Changer le mot de passe de votre compte </span>
												</div>
												<div class="card-toolbar">
													<button type="reset" class="btn btn-success mr-2">Mettre à jour</button>
													<button type="reset" class="btn btn-secondary">Annuler </button>
												</div>
											</div>

											<form class="form">
												<div class="card-body">
													
													<div class="alert alert-custom alert-light-danger fade show mb-10" role="alert">
														<div class="alert-icon">
															<span class="svg-icon svg-icon-3x svg-icon-danger">
																<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
																		<rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"></rect>
																		<rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"></rect>
																	</g>
																</svg>
																
															</span>
														</div>
														<div class="alert-text font-weight-bold">Configurer périodiquement votre mot de passe pour des raisons de sécurité avant son éxpiration.
														<br/>Pour toute tentative de connexion avec mot de passe éronnée, le sytème véroue l'accès pour des raisons de vérification. </div>
														<div class="alert-close">
															<button type="button" class="close" data-dismiss="alert" aria-label="Close">
																<span aria-hidden="true">
																	<i class="ki ki-close"></i>
																</span>
															</button>
														</div>
													</div>
													
													<div class="form-group row">
														<label class="col-xl-3 col-lg-3 col-form-label text-alert">Mot de passe actuel</label>
														<div class="col-lg-9 col-xl-6">
															<input type="password" class="form-control form-control-lg form-control-solid mb-2" value="" placeholder="Current password"/>
															<a href="#" class="text-sm font-weight-bold">Mot de passe oublié ?</a>
														</div>
													</div>
													<div class="form-group row">
														<label class="col-xl-3 col-lg-3 col-form-label text-alert">Nouveau mot de passe</label>
														<div class="col-lg-9 col-xl-6">
															<input type="password" class="form-control form-control-lg form-control-solid" value="" placeholder="New password"/>
														</div>
													</div>
													<div class="form-group row">
														<label class="col-xl-3 col-lg-3 col-form-label text-alert">Confirmer le mot de passe</label>
														<div class="col-lg-9 col-xl-6">
															<input type="password" class="form-control form-control-lg form-control-solid" value="" placeholder="Verify password"/>
														</div>
													</div>
												</div>
											</form>

										</div>
									</div>
									</div>

								</div>

							</div>

						</div>


						<br /><br /><br />

					</div>
				</div>
			</div>

		</div>

	)
}


export default SectionChangePassword