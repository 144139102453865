import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";
import { useHistory } from 'react-router-dom';

import { useLocation } from 'react-router-dom'; 

import General from '../General';





const SectionPersonnalInfo = () => {
	const { t } = useTranslation();


	let history = useHistory();

	const languages = [
		{
			code: 'fr',
			name: 'Français',
			country_code: 'fr'
		},
		{
			code: 'en',
			name: 'Anglais',
			country_code: 'gb'
		}
	]




	const currentLanguageCode = jsCookie.get('i18next') || 'fr';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);



	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps


	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect((e) => {
		if (isLoaded) {

			//history.push("/users")
			setIsPageLoaded(true);
		}
	}, [isLoaded]);

	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");




	return (
		<div>

			<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div class="d-flex flex-column-fluid">
					<div class="container">
						<div class="row"><br />
							<h5 class="text-dark font-weight-bold my-1 mr-5">Gestion des utilisateurs</h5>

							<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
								<li class="breadcrumb-item text-muted">
									<Link class="text-muted" to="/users" data-toggle="tab" data-target="#tabconfiguration" role="tab">Menu général</Link>
								</li>
								<li class="breadcrumb-item text-muted">
									<Link to="/userslist" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Liste des utilisateurs </Link>
								</li>

								<li class="breadcrumb-item text-muted">
									<Link to="/adduser" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted" >Profil de l'utilisateur</Link>
								</li>

							</ul>
						</div>
						<div class="content d-flex flex-column flex-column-fluid" id="kt_content">

							<div class="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
								<div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

									<div class="d-flex align-items-center flex-wrap mr-1">

										<button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
											<span></span>
										</button>

										<div class="d-flex align-items-baseline flex-wrap mr-5">

											<h5 class="text-dark font-weight-bold my-1 mr-5">Profil de l'utilisateur </h5>
											<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
								<li class="breadcrumb-item text-muted">
									<Link class="text-muted" to="/personnalinfo" data-toggle="tab" data-target="#tabconfiguration" role="tab">Informations personnelles</Link>
								</li>
							
							</ul>
											

										</div>

									</div>

									<div class="d-flex align-items-center">

										<a href="#" class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>

										<div class="dropdown dropdown-inline" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
											<a href="#" class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span class="svg-icon svg-icon-success svg-icon-2x">
													<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<polygon points="0 0 24 0 24 24 0 24"></polygon>
															<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
															<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000"></path>
														</g>
													</svg>

												</span>
											</a>
											<div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">

												<ul class="navi navi-hover">
													<li class="navi-header font-weight-bold py-4">
														<span class="font-size-lg">Choose Label:</span>
														<i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="" data-original-title="Click to learn more..."></i>
													</li>
													<li class="navi-separator mb-3 opacity-70"></li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-success">Customer</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-danger">Partner</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-warning">Suplier</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-primary">Member</span>
															</span>
														</a>
													</li>
													<li class="navi-item">
														<a href="#" class="navi-link">
															<span class="navi-text">
																<span class="label label-xl label-inline label-light-dark">Staff</span>
															</span>
														</a>
													</li>
													<li class="navi-separator mt-3 opacity-70"></li>
													<li class="navi-footer py-4">
														<a class="btn btn-clean font-weight-bold btn-sm" href="#">
															<i class="ki ki-plus icon-sm"></i>Add new</a>
													</li>
												</ul>

											</div>
										</div>

									</div>

								</div>
							</div>

							<div class="d-flex flex-column-fluid">

								<div class="container">

									<div class="d-flex flex-row">

										<div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
											<General/>

										
										</div>

										<div class="flex-row-fluid ml-lg-8">


											<div class="card card-custom card-stretch">

												<div class="card-header py-3">
													<div class="card-title align-items-start flex-column">
														<h3 class="card-label font-weight-bolder text-dark">Informations personnelles</h3>
														<span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
													</div>
													<div class="card-toolbar">
														<button type="reset" class="btn btn-success mr-2">Mettre à jour </button>
														<button type="reset" class="btn btn-secondary">Annuler</button>
													</div>  
												</div>

												<form class="form">

													<div class="card-body">
														<div class="row">
															<label class="col-xl-3"></label>
															<div class="col-lg-9 col-xl-6">
																<h5 class="font-weight-bold mb-6">Personnalisation de l'information</h5>
															</div>
														</div>
														
																					<div class="form-group row">
																						<label class="col-xl-3 col-lg-3 col-form-label text-left">Avatar</label>
																						<div class="col-lg-9 col-xl-9">
																							<div class="image-input image-input-outline" id="kt_user_add_avatar">
																								<div class="image-input-wrapper"></div>
																								<label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
																									<i class="fa fa-pen icon-sm text-muted"></i>
																									<input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" />
																									<input type="hidden" name="profile_avatar_remove" />
																								</label>
																								<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar">
																									<i class="ki ki-bold-close icon-xs text-muted"></i>
																								</span>
																							</div>
																						</div>
																					</div>

																					<br />
																					<div class="form-group row fv-plugins-icon-container has-danger">
																						<label class="col-xl-3 col-lg-3 col-form-label">Nom</label>
																						<div class="col-lg-9 col-xl-9">
																							<input class="form-control form-control-solid form-control-lg is-invalid" name="name" type="text" />

																						</div>
																					</div>

																					<br />
																					<div class="form-group row fv-plugins-icon-container has-danger">
																						<label class="col-xl-3 col-lg-3 col-form-label">Postnom</label>
																						<div class="col-lg-9 col-xl-9">
																							<input class="form-control form-control-solid form-control-lg is-invalid" name="lastname" type="text" />

																						</div>
																					</div>
																					<div class="form-group row fv-plugins-icon-container has-danger">
																						<label class="col-xl-3 col-lg-3 col-form-label">Prenom</label>
																						<div class="col-lg-9 col-xl-9">
																							<input class="form-control form-control-solid form-control-lg is-invalid" name="lastname" type="text" />

																						</div>
																					</div>

																					<br />
																					<div class="form-group row fv-plugins-icon-container">
																						<label class="col-form-label col-xl-3 col-lg-3">Sexe</label>
																						<div class="col-xl-9 col-lg-9">
																							<select class="form-control form-control-lg form-control-solid" name="timezone">
																								<option data-offset="-39600" value="International Date Line West">Masculin</option>
																								<option data-offset="-39600" value="Midway Island">Feminin</option>
																								<option data-offset="-39600" value="Samoa">Autre</option>


																							</select>
																							<div class="fv-plugins-message-container"></div></div>
																					</div>

																					<br />
																					<div class="form-group row fv-plugins-icon-container has-success">
																						<label class="col-xl-3 col-lg-3 col-form-label">Telephone</label>
																						<div class="col-lg-9 col-xl-9">
																							<div class="input-group input-group-solid input-group-lg">
																								<div class="input-group-prepend">
																									<span class="input-group-text">
																										<i class="flaticon2-phone"></i>
																									</span>
																								</div>
																								<input class="form-control" id="kt_inputmask_4" type="text" inputmode="text"/>
																												</div>
																							<span class="form-text text-muted">Entrer votre numero de telephone</span>
																							<div class="fv-plugins-message-container"></div></div>
																					</div>

																					<br />
																					<div class="form-group row fv-plugins-icon-container has-success">
																						<label class="col-xl-3 col-lg-3 col-form-label">Date de naissance</label>
																						<div class="col-lg-9 col-xl-9">
																							<div class="input-group input-group-solid input-group-lg">
																								<div class="input-group-prepend">
																									<span class="input-group-text">
																										<i class="flaticon2-calendar-4"></i>
																									</span>
																								</div>

																								<input type="text" class="form-control" id="kt_datepicker_1" readonly="readonly" placeholder="Select date"></input>
																							</div>
																							<span class="form-text text-muted">Entrer un numero de telephone valide</span>
																							<div class="fv-plugins-message-container"></div></div>
																					</div>

																					<br />
																					<div class="form-group row fv-plugins-icon-container has-danger">
																						<label class="col-xl-3 col-lg-3 col-form-label">Adresse Email</label>
																						<div class="col-lg-9 col-xl-9">
																							<div class="input-group input-group-solid input-group-lg">
																								<div class="input-group-prepend">
																									<span class="input-group-text">
																										<i class="flaticon2-email"></i>
																									</span>
																								</div>
																								<input type="text" class="form-control form-control-solid form-control-lg is-invalid" name="email" />
																							</div>
																						</div>
																					</div>

																					<br />
													</div>

												</form>

											</div>
										</div>

									</div>

								</div>

							</div>

						</div>


						<br /><br /><br /><br /><br /><br /><br /><br />

					</div>
				</div>
			</div>

		</div>

	)
}


export default SectionPersonnalInfo