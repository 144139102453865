
import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'; 

const RemoveScript = (scriptToremove) => {
	let allsuspects = document.getElementsByTagName("script")
    for (let i = allsuspects.length; i >= 0; i--) {
        if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null
            && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1) {
            allsuspects[i].parentNode.removeChild(allsuspects[i])
        }
    }
	return (
		<div></div>
	)
    
}

export default RemoveScript; 