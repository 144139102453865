export default class Store {

    static ajax(method, url, formulaire, type_respose = "json") {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest()
            let data = undefined
            if (formulaire) {
                data = new FormData(formulaire)
            }
            xhr.onreadystatechange = function (e) {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        resolve(this.response)
                    }
                    else if (this.status === 404) {
                        reject("Erreur de connexion serveur 404")
                    }
                    else {
                        reject("Erreur inconnue")
                    }
                }
            }
            xhr.open(method, url, true)
            if (type_respose == "json") {
                xhr.responseType = "json"
            }
            xhr.setRequestHeader("x-apikey", "59a7ad19f5a9fa0808f11931")
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*")
            xhr.setRequestHeader("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS")
            if (data) {
                xhr.send(data)
            } else {
                xhr.send()
            }

        });
    }
}
