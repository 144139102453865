import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SignIn from './SignIn';

import SignUp from './SignUp';
import ForgotPasword from './ForgotPassword';
import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useForm } from "react-hook-form";


import { useHistory } from 'react-router-dom';

import swal from 'sweetalert';

const OptContent = () => {
    const { t } = useTranslation();

    let history = useHistory();




    const languages = [
        {
            code: 'fr',
            name: 'Français',
            country_code: 'fr'
        },
        {
            code: 'en',
            name: 'Anglais',
            country_code: 'gb'
        }
    ]



    const [OTP, setOTP] = useState("");

    const currentLanguageCode = jsCookie.get('i18next') || 'fr';
    const currentLanguage = languages.find(l => l.code === currentLanguageCode);


    const [isDisabled, setIsDisabled] = useState(false)
    const renderButton = (buttonProps) => {
        if (buttonProps.remainingTime !== 0) {
            setIsDisabled(false); 
        }
        return (
            <button {...buttonProps} className="btn btn-light-primary">
                {buttonProps.remainingTime !== 0 ? `Un code vous a été envoyé par email, veuillez vérifier ! ` : "Recevoir un nouveau code"}
            </button>
        );
    };

    

    const renderTime = (remainingTime) => {
        return <span>{remainingTime} secondes du temps restant</span>;
    };
    const { register, handleSubmit, formState: { errors } } = useForm();


    let disableButton=()=>{
      setIsDisabled(true)
       
    }


    let activeButton=()=>{
        setIsDisabled(false)
    }




    let handleSubmitForm = (e) => {
        console.log(OTP);

        if (OTP.length < 7) {
            swal({
                title: "Le code que vous avez introduit est invalide ",
                text: "Confirmation du login ",
                icon: "error",
                button: "Valider",
                type:"info",
                confirmButtonColor: "#0F056B"
            });

        } else {
          
            history.push("/menufrontoff")

        }

        /*	swal({
                title: "Un code a été envoyé sur votre compte ",
                text: "Confirmation du login ",
                icon: "success",
                button: "ok",
              });*/



        return false

    }

    const optOptions = {
        opt: {
            // required: t("le champ opt est obligatoire"), 
            maxLength: {
                value: 7,
                message: "Entrer le code au complet"
            }
        },

        /*password: {
            required: "Password is required",
            minLength: {
                value: 8,
                message: "Password must have at least 8 characters"
            }
        }*/
    };


    return (
        <div>
            <div class="d-flex flex-column flex-root">

                <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                    <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 bg" >
                        <div class="d-flex flex-row-fluid flex-column justify-content-between">

                            <div class="flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 class="font-size-h1 mb-5 text-white">{t('title_img_login')}</h3>
                            </div>
                            <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                <div class="opacity-70 font-weight-bold text-white">2022 © Matrix – Software by Matrix Technologies</div>
                                <div class="d-flex">


                                    <a href="#" class="text-white ml-10">Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

                        <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                            <div class="btn-group dropright">
                                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className='fa fa-globe'></span> <b>{t("change_language_text")}</b></button>
                                <div className="dropdown-menu " >
                                    {languages.map(({ code, name, country_code }) => (
                                        <a key="{country_code}" class="dropdown-item switch-locale" data-locale={code} >
                                            <button className='btn' onClick={() => i18n.changeLanguage(code)}>
                                                <span className={'flag-icon flag-icon-' + country_code + ' mx-2'}></span>
                                                {name}

                                            </button>

                                        </a>

                                    ))}


                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <br/>
                            <form class="form" novalidate="novalidate" id="kt_login_signin_form" onSubmit={handleSubmit(handleSubmitForm)}>

                                <div class="login-form login-signin">
                                    <div class="text-center mb-10 mb-lg-20">
                                        <br></br>	<br></br>	<br></br>
                                        <div class="text-center mb-10 mb-lg-20">
                                            <br></br>
                                            <img src="../../../../../logonew.png" class="max-h-100px" alt="" />
                                            <br />

                                        </div>


                                        <center>
                                            <h2>Entrer le code </h2><br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;<OTPInput inputClassName=" input_otp" value={OTP} autoFocus="true" {...register('opt', optOptions.opt)} onChange={setOTP} OTPLength={7} otpType="number" disabled={false} secure />
                                            <br /><br /><br />
                                            <ResendOTP maxTime="180" renderButton={renderButton} renderTime={renderTime} onTimerComplete={disableButton} onClick={activeButton}/>

                                            <small className="text-danger">
                                                {errors?.opt && errors.opt.message}
                                            </small>
                                           
                                            <br />
                                            <button disabled={isDisabled}  type="submit" id="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3">Soumettre le code</button>
                                            <Link to="/" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Annuler</Link>
<br/>

                                        </center>                                                                              <br /><br /><br />
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>

        </div>

    )
}


export default OptContent