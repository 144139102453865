
import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Auth from './components/auth/Auth';
import Dashboard from './components/dashboard/Dashboard';


import CustomSwitch from './components/layout/CutomSwitch';
import Opt from './components/auth/Opt';


import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import ForgotClass from './components/auth/ForgotClass';
import ForgotConfirm from './components/auth/ForgotConfirm';
import MenuFrontOffice from './components/frontoffice/Menu';
import MenuUserManager from './components/settings/usermanager/menu/Menu';
import UserList from './components/settings/usermanager/list/ListUser';
import AddUser from './components/settings/usermanager/add/AddUser';

import ListRole from './components/settings/usermanager/rolesmanager/list/ListRole';

import ListPermission from './components/settings/usermanager/permissionsmanager/list/ListPermission';

import PersonnalInfo from './components/settings/usermanager/detailuser/personnalinfo/PersonnalInfo';
import PermissionProfile from './components/settings/usermanager/detailuser/permissions/PermissionProfile';
import AccountInfo from './components/settings/usermanager/detailuser/accountinfo/AccountInfo';
import ChangePassword from './components/settings/usermanager/detailuser/changepassword/ChangePassword';

import Agences from './components/settings/usermanager/agencesmanager/Agences';
import Agence_edit from './components/settings/usermanager/agencesmanager/Edit';
import Agence_add from './components/settings/usermanager/agencesmanager/Add';


import $ from 'jquery'; 





i18n
.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
  
   // lng: document.querySelector('html').lang, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    supportedLngs:['en', 'fr'], 

    detection : {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

      caches : ['cookie']

    }, 
    backend : {
      loadPath: '/assets/locales/{{lng}}/translation.json'
    },
    react:{useSuspense :false}

 
  });



class App extends Component {
  
  componentDidMount() {
    {

       

    
    } 

  }

  render() {

  
    return (
      <BrowserRouter>
        <div>
          <CustomSwitch >
            
          <Route exact path="/forgotconfirm" component={ForgotConfirm} />
            <Route exact path="/forgotpassword" component={ForgotClass} />
            <Route exact path="/" component={Auth} />
            <Route exact path="/otp" component={Opt} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/menufrontoff" component={MenuFrontOffice} />
            <Route exact path="/users" component={MenuUserManager} />
            <Route exact path="/userslist" component={UserList} />
            <Route exact path="/adduser" component={AddUser} />
              <Route exact path="/listrole" component={ListRole} />
            <Route exact path="/listpermission" component={ListPermission} />
          
            <Route exact path="/personnalinfo" component={PersonnalInfo} />
            <Route exact path="/changepassword" component={ChangePassword} />
            <Route exact path="/permissionprofile" component={PermissionProfile} />
            <Route exact path="/accountinfo" component={AccountInfo} />
            <Route exact path="/agences" component={Agences} />
            <Route exact path="/add_agence" component={Agence_add} />
            <Route exact path="/edit" component={Agence_edit} />
            
          
          </CustomSwitch>
         </div>

      </BrowserRouter>

    ); 

  }


}

export default App;
