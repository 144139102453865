import React, { useState } from 'react';
import { t } from 'i18next';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";



const ForgotPasword = () => {


	let history = useHistory();




	const [formData, setFormData] = useState({
		username: "",
		password: "",
		page_opt: false
	
	})
	
	const handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		formData[name] = value;
		setFormData(formData);
	};
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	//const handleError = (errors) => { };
	
	const signinOptions = {
		username: { required: t("notif_champ_username") },
		password: { required:t("notif_password") },
		email: {
			required: "email est réquis ",
			pattern: {
				value: /^\S+@\S+$/i,
				message: "Entrer une adresse email valide"
			 }
			
		}
	};
	
	/*handleChange = handleChange.bind(useState)
	onSubmitForm = onSubmitForm.bind(u)*/
	
	
	let handleSubmitForm = (e) => {
		
		/*swal({
			title: "Un code a été envoyé sur votre compte ",
			text: "Confirmation du login ",
			icon: "success",
			button: "ok",
		  });*/
	
		  history.push("/forgotconfirm")
	
		return false
	
	}
	


	return (<div>
		<div class="text-center mb-10 mb-lg-20">
			<br></br>	<br></br>	<br></br>	<br></br>	<br></br>	<br></br>	<br></br>	<br></br>

			<h3 class="font-size-h1">{t("forgot_password_text")} </h3>
			<p class="text-muted font-weight-bold">{t("title_forgot")}</p>
		</div>
		<form class="form" novalidate="novalidate" id="kt_login_forgot_form" onSubmit={handleSubmit(handleSubmitForm)}>
			<div class="form-group">
				<input  class="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Entrer votre adresse email" name="email" autocomplete="off"  onChange={handleChange} {...register('email', signinOptions.email)} />
				<small className="text-danger">
						{errors?.email && errors.email.message}
					</small>
			</div>
		
			<div class="form-group d-flex flex-wrap flex-center">
				<button type="submit" id="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">{t("btn_validate")}</button>
				<Link to="/" type="button" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4" >{t("btn_cancel")}</Link>

			</div>
		</form>
		<br></br>	<br></br>	<br></br>	<br></br>	<br></br>	<br></br>	

	</div>
	)
}

export default ForgotPasword