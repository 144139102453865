
import React, { Component } from 'react';




import Header from '../general/Header';
import Section from './Section';
import Footer from '../general/Footer';
import { Helmet } from 'react-helmet';


import { useHistory } from 'react-router-dom';

import $ from "jquery";
import ScriptImport from '../layout/ScriptImport';
import RemoveScript from '../layout/RemoveSrc';


class Dashbooard extends Component {

    constructor(props) {
        super(props);
        this.state = { show: true };
        this.handleLoad = this.handleLoad.bind(this);
        const { navigation } = this.props;
        this.navigation = navigation;




    
    }

    componentDidUpdate() {
        {



        }
    }
    componentDidMount() {
        {

           // if (!document.getElementsByClassName("script_import")) {

            
              //
          
              ScriptImport("metronic/plugins/custom/prismjs/prismjs.bundle15aa.js?v=7.2.2");
              ScriptImport("metronic/js/scripts.bundle15aa.js?v=7.2.2");
 
                ScriptImport("metronic/js/pages/widgets15aa.js");
          //  }



        }


    }
    componentWillUnmount() {


        {

           // RemoveScript("metronic/js/pages/widgets15aa.js");
      
            window.removeEventListener('load', this.handleLoad)


        }
    }

    handleLoad(e) {
      //  console.log(this.props)


    }

    render() {

        return (
            <div>
                <Header />
                <Section />
                <Footer />



            </div>



        );
    }


}



export default Dashbooard;