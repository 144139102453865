import React from 'react';
import { Link } from 'react-router-dom';

const SignUp = () => {
	return (<div>
		<div class="text-center mb-10 mb-lg-20">
			<br></br>	<br></br>	<br></br>	<br></br>	<br></br>
			<h3 class="font-size-h1">S'enrégistrer</h3>
			<p class="text-muted font-weight-bold">Entrer les détails de votre profil pour créer le compte</p>
		</div>
		<form class="form" novalidate="novalidate" id="kt_login_signup_form">
			<div class="form-group">
				<input class="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Fullname" name="fullname" autocomplete="off" />
			</div>
			<div class="form-group">
				<input class="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Email" name="email" autocomplete="off" />
			</div>
			<div class="form-group">
				<input class="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder="Password" name="password" autocomplete="off" />
			</div>
			<div class="form-group">
				<input class="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder="Confirm password" name="cpassword" autocomplete="off" />
			</div>
			<div class="form-group">
				<label class="checkbox mb-0">
					<input type="checkbox" name="agree" />
					<span></span>J'accèpte  <br />
					<a href="#"> les termes et conditions</a></label>
			</div>
			<div class="form-group d-flex flex-wrap flex-center">
				<button type="button" id="kt_login_signup_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Valider </button>
				<Link to="/" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Annuler</Link>
			</div>
		</form>
		<br></br>	<br></br>	<br></br>	<br></br>
	</div>
	)
}

export default SignUp