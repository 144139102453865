
import React, { Component } from 'react';




import Header from '../general/Header';
import SectionMenuFo from './SectionMenuFo';
import Footer from '../general/Footer';
import { Helmet } from 'react-helmet';

import ScriptImport from '../layout/ScriptImport';
import RemoveScript from '../layout/RemoveSrc';

import { useHistory } from 'react-router-dom';

import $ from "jquery";



class MenuFrontOffice extends Component {

    constructor(props) {
        super(props);
        this.state = { show: true };
        this.handleLoad = this.handleLoad.bind(this);
        const { navigation } = this.props;
        this.navigation = navigation;




    }

    componentDidMount() {
        {

           // if (!document.getElementsByClassName("script_import")) {

              
              //
                ScriptImport("metronic/js/scripts.bundle15aa.js?v=7.2.2");

                ScriptImport("metronic/js/pages/widgets15aa.js");
          //  }



        }


    }

    componentDidUpdate() {
        {

        }
    }
    componentWillUnmount() {


       {
            
        RemoveScript("metronic/js/pages/widgets15aa.js");
            //window.removeEventListener('load', this.handleLoad)


        }
    }

    handleLoad(e) {
        //  console.log(this.props)


    }

    render() {

        return (
            <div>
                <Header />
                <SectionMenuFo />
                <Footer />



            </div>



        );
    }


}



export default MenuFrontOffice;