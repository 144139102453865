import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'; 


const ScriptImport = (scriptToAppend) => {

		const script = document.createElement("script");

		script.src = scriptToAppend;
		script.setAttribute("className", "script_import"); 
	
		script.async = true;
		document.body.appendChild(script);
	
	return (
		
		<div></div>
	)
    
}

export default ScriptImport; 