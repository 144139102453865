
import React, { Component } from 'react';


import Header from '../../../../general/Header';

import Footer from '../../../../general/Footer';
import { Helmet } from 'react-helmet';

import ScriptImport from '../../../../layout/ScriptImport';
import RemoveScript from '../../../../layout/RemoveSrc';



import { useHistory } from 'react-router-dom';

import $ from "jquery";

import SectionPersonnalInfo from './SectionPersonnalInfo';


class PersonnalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = { show: true };
        this.handleLoad = this.handleLoad.bind(this);
        const { navigation } = this.props;
        this.navigation = navigation;




    }

    componentDidMount() {
        
        {
            ScriptImport("metronic/js/pages/custom/user/add-user15aa.js?v=7.2.2")

            ScriptImport("metronic/js/pages/crud/forms/widgets/bootstrap-datepicker15aa.js?v=7.2.2")
            ScriptImport("metronic/js/pages/crud/forms/widgets/input-mask15aa.js?v=7.2.2")
            
         
        }


    }

    componentDidUpdate() {
        {
   
           
        }
    }
    componentWillUnmount() {


        {
        
            window.removeEventListener('load', this.handleLoad)


        }
    }

    handleLoad(e) {
      //  console.log(this.props)
      

    }

    render() {

        return (
            <div>
                <Header />
               
                <SectionPersonnalInfo />
              
                <Footer />

            </div>



        );
    }


}



export default PersonnalInfo;