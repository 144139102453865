//
import Store from '../../../../store/Store'
//
import React, { useEffect, useState, Component } from 'react';
import { Navigate, Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import jqueryI18next from 'jquery-i18next';
import jsCookie from 'js-cookie';
import i18n from "i18next";
import swal from 'sweetalert'
import { Button, TextField, Select, MenuItem, InputLabel, TextareaAutosize, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import Header from '../../../general/Header';
import Footer from '../../../general/Footer';
import ScriptImport from '../../../layout/ScriptImport';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


export default function Agence_edit() {
    const classes = useStyles();
    const { t } = useTranslation();

    const languages = [
        {
            code: 'fr',
            name: 'Français',
            country_code: 'fr'
        },
        {
            code: 'en',
            name: 'Anglais',
            country_code: 'gb'
        }
    ]

    function send(e) {
        e.preventDefault()
        const resultat = Store.ajax(
            "POST",
            '',
            e.target,
            'text'
        ).then((resultat) => {
            swal({
                title: "Notification",
                text: resultat,
                icon: "success",
            })
            window.location = "/roles"
            return false
        }).catch((error) => {
            swal({
                title: "Notification",
                text: error,
                icon: "error",
            })
            return false
        })
        //console.log(resultat)
        //console.log(e.target)
    }

    const currentLanguageCode = jsCookie.get('i18next') || 'fr';
    const currentLanguage = languages.find(l => l.code === currentLanguageCode);

    function send(e) {
        e.preventDefault()
        const resultat = Store.ajax(
            "POST",
            '',
            e.target,
            'text'
        ).then((resultat) => {
            swal({
                title: "Notification",
                text: resultat,
                icon: "success",
            })
            window.location = "/roles"
            return false
        }).catch((error) => {
            swal({
                title: "Notification",
                text: error,
                icon: "error",
            })
            return false
        })
        //console.log(resultat)
        //console.log(e.target)
    }


    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps



    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect((e) => {
        if (isLoaded) {
            ScriptImport("metronic/js/scripts.bundle15aa.js?v=7.2.2");

            ScriptImport("metronic/js/pages/custom/wizard/wizard-215aa.js?v=7.2.2");
            ScriptImport("metronic/js/pages/crud/forms/widgets/select215aa.js?v=7.2.2");

            ScriptImport("metronic/js/pages/crud/forms/widgets/bootstrap-timepicker15aa.js?v=7.2.2");

            setIsPageLoaded(true);
        }
    }, [isLoaded]);


    return <div>
        <Header />

        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div class="d-flex flex-column-fluid">
                <div class="container">
                    <div class="row"><br />
                        <h5 class="text-dark font-weight-bold my-1 mr-5">Gestion des utilisateurs </h5>

                        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">

                            <li class="breadcrumb-item text-muted">
                                <Link class="text-muted" to="/users" data-toggle="tab" data-target="#tabconfiguration" role="tab">Menu général</Link>
                            </li>
                            <li class="breadcrumb-item text-muted">
                                <Link to="/agences" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Liste des agences  </Link>
                            </li>
                            <li class="breadcrumb-item text-muted">
                                <Link to="/add_agence" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Ajout d'une agence  </Link>
                            </li>

                        </ul>
                    </div>
                    <div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                        <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

                            <div class="d-flex align-items-center flex-wrap mr-2">

                                <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Edition de l'agence <b>PLACE COMMERCIALE</b> </h5>

                                <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>



                            </div>

                            <div class="d-flex align-items-center">
                            <Link to="/agences" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Retour</Link>

                                <div class="btn-group ml-2">
                                    <button type="button" class="btn btn-primary font-weight-bold btn-sm px-3 font-size-base">Mettre à jour  </button>
                                    <button type="button" class="btn btn-primary font-weight-bold btn-sm px-3 font-size-base dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                    <div class="dropdown-menu dropdown-menu-sm p-0 m-0 dropdown-menu-right">
                                        <ul class="navi py-5">

                                            <li class="navi-item">
                                                <a href="#" class="navi-link">
                                                    <span class="navi-icon">
                                                        <i class="flaticon2-medical-records"></i>
                                                    </span>
                                                    <span class="navi-text">Mettre à jour &amp; Ajouter </span>
                                                </a>
                                            </li>
                                            <li class="navi-item">
                                                <a href="#" class="navi-link">
                                                    <span class="navi-icon">
                                                        <i class="flaticon2-hourglass-1"></i>
                                                    </span>
                                                    <span class="navi-text">Mettre à jour  &amp; Annuler</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="card card-custom">
                        <div class="card-body p-0">

                            <div class="wizard wizard-2" id="kt_wizard" data-wizard-state="first" data-wizard-clickable="false">

                                <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">

                                    <div class="wizard-steps">
                                        <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                            <div class="wizard-wrapper">
                                                <div class="wizard-icon">
                                                <span class="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z" fill="#000000"/>
    </g>
</svg></span>
                                                </div>
                                                <div class="wizard-label">
                                                    <h3 class="wizard-title">Informations générales</h3>
                                                    <div class="wizard-desc">Déscription de l'agence et horaire</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
                                            <div class="wizard-wrapper">
                                                <div class="wizard-icon">
                                                    <span class="svg-icon svg-icon-2x">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <path d="M12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 Z M14.1654881,7.35483745 L9.61055177,10.3622525 C9.47921741,10.4489666 9.39637436,10.592455 9.38694497,10.7495509 L9.05991526,16.197949 C9.04337012,16.4735952 9.25341309,16.7104632 9.52905936,16.7270083 C9.63705011,16.7334903 9.74423017,16.7047714 9.83451193,16.6451626 L14.3894482,13.6377475 C14.5207826,13.5510334 14.6036256,13.407545 14.613055,13.2504491 L14.9400847,7.80205104 C14.9566299,7.52640477 14.7465869,7.28953682 14.4709406,7.27299168 C14.3629499,7.26650974 14.2557698,7.29522855 14.1654881,7.35483745 Z" fill="#000000"></path>
                                                            </g>
                                                        </svg>

                                                    </span>
                                                </div>
                                                <div class="wizard-label">
                                                    <h3 class="wizard-title">Localisation de l'agence</h3>
                                                    <div class="wizard-desc">Décrivez les coordonnées géographiques</div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
                                            <div class="wizard-wrapper">
                                                <div class="wizard-icon">
                                                    <span class="svg-icon svg-icon-2x">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <path d="M19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 Z M3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="2"></circle>
                                                                <path d="M12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 Z M12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 Z" fill="#000000" fill-rule="nonzero"></path>
                                                            </g>
                                                        </svg>

                                                    </span>
                                                </div>
                                                <div class="wizard-label">
                                                    <h3 class="wizard-title">Adresse de l'agence</h3>
                                                    <div class="wizard-desc">Adresse, Pays, Ville, Province, Commune</div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
                                            <div class="wizard-wrapper">
                                                <div class="wizard-icon">
                                                    <span class="svg-icon svg-icon-2x">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <path d="M9,10 L9,19 L10.1525987,19.3841996 C11.3761964,19.7920655 12.6575468,20 13.9473319,20 L17.5405883,20 C18.9706314,20 20.2018758,18.990621 20.4823303,17.5883484 L21.231529,13.8423552 C21.5564648,12.217676 20.5028146,10.6372006 18.8781353,10.3122648 C18.6189212,10.260422 18.353992,10.2430672 18.0902299,10.2606513 L14.5,10.5 L14.8641964,6.49383981 C14.9326895,5.74041495 14.3774427,5.07411874 13.6240179,5.00562558 C13.5827848,5.00187712 13.5414031,5 13.5,5 L13.5,5 C12.5694044,5 11.7070439,5.48826024 11.2282564,6.28623939 L9,10 Z" fill="#000000"></path>
                                                                <rect fill="#000000" opacity="0.3" x="2" y="9" width="5" height="11" rx="1"></rect>
                                                            </g>
                                                        </svg>

                                                    </span>
                                                </div>
                                                <div class="wizard-label">
                                                    <h3 class="wizard-title">Terminé</h3>
                                                    <div class="wizard-desc">Révision et validation</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">

                                    <div class="row">
                                        <div class="offset-xxl-2 col-xxl-8">
                                            <form class="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">

                                                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                                    <h4 class="mb-10 font-weight-bold text-dark">Déscription de l'agence</h4>

                                                    <div class="form-group fv-plugins-icon-container">
                                                        <label>Désignation</label>
                                                        <input type="text" class="form-control form-control-solid form-control-lg" name="fname" placeholder="Désignation" />
                                                        <span class="form-text text-muted">Entrer le nom de l'agence</span>
                                                        <div class="fv-plugins-message-container"></div></div>

                                                  

                                                    <label>Nom du chef d'agence</label>
                                                    <div class="form-group fv-plugins-icon-container">
                                                        <select name="loccountry" class="form-control form-control-solid form-control-lg">
                                                            <option value="">Select</option>
                                                            <option value="AF">KIBAKWETO AKARIGO Landry </option>
                                                            <option value="AX">ARAZA AKLISTU Archippe</option>

                                                        </select>
                                                    </div>


                                                    <div class="form-group row">
												    <label>Agents affectés</label>
                                                
                                                <div class="col-lg-12 col-md-12 col-sm-12">
													<select class="form-control select2" id="kt_select2_3" name="param" multiple="multiple">
														<optgroup label="Directeur Générale">
                                                        <option value="AK" selected="selected">ARAZA AKLISTU</option>
														<option value="HI">KIBAKWETO AKARIGO Landry</option>

                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>

														</optgroup>
                                                        <optgroup label="Caissier">
															<option value="AK" selected="selected">ARAZA AKLISTU</option>
															<option value="HI">KIBAKWETO AKARIGO Landry</option>

                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>
                                                            <option value="HI">KIBAKWETO AKARIGO Landry</option>

														</optgroup>
														
													</select>
												</div>
											</div>
                                                    <div class="row">
                                                        <div class="col-xl-12">

                                                            <div class="form-group row">

                                                                <div class="col-lg-6 col-md-9 col-sm-12">
                                                                    <label >Heure d'ouverture</label>
                                                                    <div class="input-group timepicker">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text">
                                                                                <span class="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                                        <path d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z" fill="#000000" />
                                                                                        <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z" fill="#000000" opacity="0.3" />
                                                                                        <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                                                                                    </g>
                                                                                </svg></span>
                                                                            </span>
                                                                        </div>
                                                                        <input class="form-control" id="kt_timepicker_3" readonly="readonly" placeholder="Select time" type="text" size="20" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-9 col-sm-12">
                                                                    <label >Heure de fermeture</label>
                                                                    <div class="input-group timepicker">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text">
                                                                                <span class="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                                        <path d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z" fill="#000000" />
                                                                                        <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z" fill="#000000" opacity="0.3" />
                                                                                        <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                                                                                    </g>
                                                                                </svg></span>
                                                                            </span>
                                                                        </div>
                                                                        <input class="form-control" id="kt_timepicker_3" readonly="readonly" placeholder="Select time" type="text" size="20" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                    </div>
                                                </div>

                                                <div class="pb-5" data-wizard-type="step-content">
                                                    <h4 class="mb-10 font-weight-bold text-dark">Localisation </h4>
                                                    <div class="row">
                                                        <div class="col-xl-6">

                                                            <div class="form-group fv-plugins-icon-container">
                                                                <label>Longitude </label>
                                                                <input type="text" class="form-control form-control-solid form-control-lg" name="address1" placeholder="Entrer la longitude" />
                                                                <span class="form-text text-muted">Entrer la longitude</span>
                                                                <div class="fv-plugins-message-container"></div></div>

                                                        </div>
                                                        <div class="col-xl-6">

                                                            <div class="form-group">
                                                                <label>Latitude</label>
                                                                <input type="text" class="form-control form-control-solid form-control-lg" name="address2" placeholder="Entrer la latitude" />
                                                                <span class="form-text text-muted">Entrer la latitude</span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                          
                                                <div class="pb-5" data-wizard-type="step-content">
                                                    <h4 class="mb-10 font-weight-bold text-dark">Se</h4>
                                                    <div class="row">
                                                       
                                                     
                                                    </div>
                                                    <div class="row">
                                                    <div class="col-xl-6">

<div class="form-group fv-plugins-icon-container">
    <label>Adresse de l'agence</label>
    <input type="text" class="form-control form-control-solid form-control-lg" name="locaddress1" placeholder="Entrer l'adresse de l'agence" />
    <span class="form-text text-muted">Entrer l'adresse </span>
    <div class="fv-plugins-message-container"></div></div>

</div>
                                                        <div class="col-xl-6">

                                                            <div class="form-group fv-plugins-icon-container">
                                                                <label>Ville</label>
                                                                <input type="text" class="form-control form-control-solid form-control-lg" name="loccity" placeholder="Entrer votre ville"  />
                                                                <span class="form-text text-muted">Entrer votre ville</span>
                                                                <div class="fv-plugins-message-container"></div></div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-6">

                                                            <div class="form-group fv-plugins-icon-container">
                                                                <label>Province</label>
                                                                <input type="text" class="form-control form-control-solid form-control-lg" name="locstate" placeholder="Province" value="VIC" />
                                                                <span class="form-text text-muted">Entrer votre province</span>
                                                                <div class="fv-plugins-message-container"></div></div>

                                                        </div>
                                                        <div class="col-xl-6">

                                                            <div class="form-group fv-plugins-icon-container">
                                                                <label>Pays</label>
                                                                <select name="loccountry" class="form-control form-control-solid form-control-lg">
                                                                    <option value="">Select</option>
                                                                    <option value="AF">Afghanistan</option>
                                                                    <option value="AX">Åland Islands</option>
                                                                    <option value="AL">Albania</option>
                                                                    <option value="DZ">Algeria</option>
                                                                    <option value="AS">American Samoa</option>
                                                                    <option value="AD">Andorra</option>
                                                                    <option value="AO">Angola</option>
                                                                    <option value="AI">Anguilla</option>
                                                                    <option value="AQ">Antarctica</option>
                                                                    <option value="AG">Antigua and Barbuda</option>
                                                                    <option value="AR">Argentina</option>
                                                                    <option value="AM">Armenia</option>
                                                                    <option value="AW">Aruba</option>
                                                                    <option value="AU" selected="selected">Australia</option>
                                                                    <option value="AT">Austria</option>
                                                                    <option value="AZ">Azerbaijan</option>
                                                                    <option value="BS">Bahamas</option>
                                                                    <option value="BH">Bahrain</option>
                                                                    <option value="BD">Bangladesh</option>
                                                                    <option value="BB">Barbados</option>
                                                                    <option value="BY">Belarus</option>
                                                                    <option value="BE">Belgium</option>
                                                                    <option value="BZ">Belize</option>
                                                                    <option value="BJ">Benin</option>
                                                                    <option value="BM">Bermuda</option>
                                                                    <option value="BT">Bhutan</option>
                                                                    <option value="BO">Bolivia, Plurinational State of</option>
                                                                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                                    <option value="BA">Bosnia and Herzegovina</option>
                                                                    <option value="BW">Botswana</option>
                                                                    <option value="BV">Bouvet Island</option>
                                                                    <option value="BR">Brazil</option>
                                                                    <option value="IO">British Indian Ocean Territory</option>
                                                                    <option value="BN">Brunei Darussalam</option>
                                                                    <option value="BG">Bulgaria</option>
                                                                    <option value="BF">Burkina Faso</option>
                                                                    <option value="BI">Burundi</option>
                                                                    <option value="KH">Cambodia</option>
                                                                    <option value="CM">Cameroon</option>
                                                                    <option value="CA">Canada</option>
                                                                    <option value="CV">Cape Verde</option>
                                                                    <option value="KY">Cayman Islands</option>
                                                                    <option value="CF">Central African Republic</option>
                                                                    <option value="TD">Chad</option>
                                                                    <option value="CL">Chile</option>
                                                                    <option value="CN">China</option>
                                                                    <option value="CX">Christmas Island</option>
                                                                    <option value="CC">Cocos (Keeling) Islands</option>
                                                                    <option value="CO">Colombia</option>
                                                                    <option value="KM">Comoros</option>
                                                                    <option value="CG">Congo</option>
                                                                    <option value="CD">Congo, the Democratic Republic of the</option>
                                                                    <option value="CK">Cook Islands</option>
                                                                    <option value="CR">Costa Rica</option>
                                                                    <option value="CI">Côte d'Ivoire</option>
                                                                    <option value="HR">Croatia</option>
                                                                    <option value="CU">Cuba</option>
                                                                    <option value="CW">Curaçao</option>
                                                                    <option value="CY">Cyprus</option>
                                                                    <option value="CZ">Czech Republic</option>
                                                                    <option value="DK">Denmark</option>
                                                                    <option value="DJ">Djibouti</option>
                                                                    <option value="DM">Dominica</option>
                                                                    <option value="DO">Dominican Republic</option>
                                                                    <option value="EC">Ecuador</option>
                                                                    <option value="EG">Egypt</option>
                                                                    <option value="SV">El Salvador</option>
                                                                    <option value="GQ">Equatorial Guinea</option>
                                                                    <option value="ER">Eritrea</option>
                                                                    <option value="EE">Estonia</option>
                                                                    <option value="ET">Ethiopia</option>
                                                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                                                    <option value="FO">Faroe Islands</option>
                                                                    <option value="FJ">Fiji</option>
                                                                    <option value="FI">Finland</option>
                                                                    <option value="FR">France</option>
                                                                    <option value="GF">French Guiana</option>
                                                                    <option value="PF">French Polynesia</option>
                                                                    <option value="TF">French Southern Territories</option>
                                                                    <option value="GA">Gabon</option>
                                                                    <option value="GM">Gambia</option>
                                                                    <option value="GE">Georgia</option>
                                                                    <option value="DE">Germany</option>
                                                                    <option value="GH">Ghana</option>
                                                                    <option value="GI">Gibraltar</option>
                                                                    <option value="GR">Greece</option>
                                                                    <option value="GL">Greenland</option>
                                                                    <option value="GD">Grenada</option>
                                                                    <option value="GP">Guadeloupe</option>
                                                                    <option value="GU">Guam</option>
                                                                    <option value="GT">Guatemala</option>
                                                                    <option value="GG">Guernsey</option>
                                                                    <option value="GN">Guinea</option>
                                                                    <option value="GW">Guinea-Bissau</option>
                                                                    <option value="GY">Guyana</option>
                                                                    <option value="HT">Haiti</option>
                                                                    <option value="HM">Heard Island and McDonald Islands</option>
                                                                    <option value="VA">Holy See (Vatican City State)</option>
                                                                    <option value="HN">Honduras</option>
                                                                    <option value="HK">Hong Kong</option>
                                                                    <option value="HU">Hungary</option>
                                                                    <option value="IS">Iceland</option>
                                                                    <option value="IN">India</option>
                                                                    <option value="ID">Indonesia</option>
                                                                    <option value="IR">Iran, Islamic Republic of</option>
                                                                    <option value="IQ">Iraq</option>
                                                                    <option value="IE">Ireland</option>
                                                                    <option value="IM">Isle of Man</option>
                                                                    <option value="IL">Israel</option>
                                                                    <option value="IT">Italy</option>
                                                                    <option value="JM">Jamaica</option>
                                                                    <option value="JP">Japan</option>
                                                                    <option value="JE">Jersey</option>
                                                                    <option value="JO">Jordan</option>
                                                                    <option value="KZ">Kazakhstan</option>
                                                                    <option value="KE">Kenya</option>
                                                                    <option value="KI">Kiribati</option>
                                                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                                                    <option value="KR">Korea, Republic of</option>
                                                                    <option value="KW">Kuwait</option>
                                                                    <option value="KG">Kyrgyzstan</option>
                                                                    <option value="LA">Lao People's Democratic Republic</option>
                                                                    <option value="LV">Latvia</option>
                                                                    <option value="LB">Lebanon</option>
                                                                    <option value="LS">Lesotho</option>
                                                                    <option value="LR">Liberia</option>
                                                                    <option value="LY">Libya</option>
                                                                    <option value="LI">Liechtenstein</option>
                                                                    <option value="LT">Lithuania</option>
                                                                    <option value="LU">Luxembourg</option>
                                                                    <option value="MO">Macao</option>
                                                                    <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                                                    <option value="MG">Madagascar</option>
                                                                    <option value="MW">Malawi</option>
                                                                    <option value="MY">Malaysia</option>
                                                                    <option value="MV">Maldives</option>
                                                                    <option value="ML">Mali</option>
                                                                    <option value="MT">Malta</option>
                                                                    <option value="MH">Marshall Islands</option>
                                                                    <option value="MQ">Martinique</option>
                                                                    <option value="MR">Mauritania</option>
                                                                    <option value="MU">Mauritius</option>
                                                                    <option value="YT">Mayotte</option>
                                                                    <option value="MX">Mexico</option>
                                                                    <option value="FM">Micronesia, Federated States of</option>
                                                                    <option value="MD">Moldova, Republic of</option>
                                                                    <option value="MC">Monaco</option>
                                                                    <option value="MN">Mongolia</option>
                                                                    <option value="ME">Montenegro</option>
                                                                    <option value="MS">Montserrat</option>
                                                                    <option value="MA">Morocco</option>
                                                                    <option value="MZ">Mozambique</option>
                                                                    <option value="MM">Myanmar</option>
                                                                    <option value="NA">Namibia</option>
                                                                    <option value="NR">Nauru</option>
                                                                    <option value="NP">Nepal</option>
                                                                    <option value="NL">Netherlands</option>
                                                                    <option value="NC">New Caledonia</option>
                                                                    <option value="NZ">New Zealand</option>
                                                                    <option value="NI">Nicaragua</option>
                                                                    <option value="NE">Niger</option>
                                                                    <option value="NG">Nigeria</option>
                                                                    <option value="NU">Niue</option>
                                                                    <option value="NF">Norfolk Island</option>
                                                                    <option value="MP">Northern Mariana Islands</option>
                                                                    <option value="NO">Norway</option>
                                                                    <option value="OM">Oman</option>
                                                                    <option value="PK">Pakistan</option>
                                                                    <option value="PW">Palau</option>
                                                                    <option value="PS">Palestinian Territory, Occupied</option>
                                                                    <option value="PA">Panama</option>
                                                                    <option value="PG">Papua New Guinea</option>
                                                                    <option value="PY">Paraguay</option>
                                                                    <option value="PE">Peru</option>
                                                                    <option value="PH">Philippines</option>
                                                                    <option value="PN">Pitcairn</option>
                                                                    <option value="PL">Poland</option>
                                                                    <option value="PT">Portugal</option>
                                                                    <option value="PR">Puerto Rico</option>
                                                                    <option value="QA">Qatar</option>
                                                                    <option value="RE">Réunion</option>
                                                                    <option value="RO">Romania</option>
                                                                    <option value="RU">Russian Federation</option>
                                                                    <option value="RW">Rwanda</option>
                                                                    <option value="BL">Saint Barthélemy</option>
                                                                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                                                    <option value="KN">Saint Kitts and Nevis</option>
                                                                    <option value="LC">Saint Lucia</option>
                                                                    <option value="MF">Saint Martin (French part)</option>
                                                                    <option value="PM">Saint Pierre and Miquelon</option>
                                                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                                                    <option value="WS">Samoa</option>
                                                                    <option value="SM">San Marino</option>
                                                                    <option value="ST">Sao Tome and Principe</option>
                                                                    <option value="SA">Saudi Arabia</option>
                                                                    <option value="SN">Senegal</option>
                                                                    <option value="RS">Serbia</option>
                                                                    <option value="SC">Seychelles</option>
                                                                    <option value="SL">Sierra Leone</option>
                                                                    <option value="SG">Singapore</option>
                                                                    <option value="SX">Sint Maarten (Dutch part)</option>
                                                                    <option value="SK">Slovakia</option>
                                                                    <option value="SI">Slovenia</option>
                                                                    <option value="SB">Solomon Islands</option>
                                                                    <option value="SO">Somalia</option>
                                                                    <option value="ZA">South Africa</option>
                                                                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                                    <option value="SS">South Sudan</option>
                                                                    <option value="ES">Spain</option>
                                                                    <option value="LK">Sri Lanka</option>
                                                                    <option value="SD">Sudan</option>
                                                                    <option value="SR">Suriname</option>
                                                                    <option value="SJ">Svalbard and Jan Mayen</option>
                                                                    <option value="SZ">Swaziland</option>
                                                                    <option value="SE">Sweden</option>
                                                                    <option value="CH">Switzerland</option>
                                                                    <option value="SY">Syrian Arab Republic</option>
                                                                    <option value="TW">Taiwan, Province of China</option>
                                                                    <option value="TJ">Tajikistan</option>
                                                                    <option value="TZ">Tanzania, United Republic of</option>
                                                                    <option value="TH">Thailand</option>
                                                                    <option value="TL">Timor-Leste</option>
                                                                    <option value="TG">Togo</option>
                                                                    <option value="TK">Tokelau</option>
                                                                    <option value="TO">Tonga</option>
                                                                    <option value="TT">Trinidad and Tobago</option>
                                                                    <option value="TN">Tunisia</option>
                                                                    <option value="TR">Turkey</option>
                                                                    <option value="TM">Turkmenistan</option>
                                                                    <option value="TC">Turks and Caicos Islands</option>
                                                                    <option value="TV">Tuvalu</option>
                                                                    <option value="UG">Uganda</option>
                                                                    <option value="UA">Ukraine</option>
                                                                    <option value="AE">United Arab Emirates</option>
                                                                    <option value="GB">United Kingdom</option>
                                                                    <option value="US">United States</option>
                                                                    <option value="UM">United States Minor Outlying Islands</option>
                                                                    <option value="UY">Uruguay</option>
                                                                    <option value="UZ">Uzbekistan</option>
                                                                    <option value="VU">Vanuatu</option>
                                                                    <option value="VE">Venezuela, Bolivarian Republic of</option>
                                                                    <option value="VN">Viet Nam</option>
                                                                    <option value="VG">Virgin Islands, British</option>
                                                                    <option value="VI">Virgin Islands, U.S.</option>
                                                                    <option value="WF">Wallis and Futuna</option>
                                                                    <option value="EH">Western Sahara</option>
                                                                    <option value="YE">Yemen</option>
                                                                    <option value="ZM">Zambia</option>
                                                                    <option value="ZW">Zimbabwe</option>
                                                                </select>
                                                                <div class="fv-plugins-message-container"></div></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="pb-5" data-wizard-type="step-content">

                                                    <h4 class="mb-10 font-weight-bold text-dark">Révision et validation</h4>
                                                    <h6 class="font-weight-bolder mb-3">Déscription générale</h6>
                                                    <div class="text-dark-50 line-height-lg">
                                                        <div>Nom de l'agence &nbsp;&nbsp; : <b>PLACE COMMERCIALE</b></div>
                                                        <div>Nom du chef d'agence : KIBAKWETO AKARIGO Landry</div>
                                                        <div>Nombre des agents affectés : 10 </div>
                                                    </div>
                                                    <div class="separator separator-dashed my-5"></div>


                                                    <h6 class="font-weight-bolder mb-3">Coordonnées géographiques</h6>
                                                    <div class="text-dark-50 line-height-lg">
                                                        <div>Longitude: 455566</div>
                                                        <div>Latitude : 455566</div>
                                                      
                                                    </div>
                                                    <div class="separator separator-dashed my-5"></div>


                                                 

                                                    <h6 class="font-weight-bolder mb-3">Adresse:</h6>
                                                    <div class="text-dark-50 line-height-lg">
                                                        <div>Address Line 1</div>
                                                        <div>Address Line 2</div>
                                                        <div>Preston 3072, VIC, Australia</div>
                                                    </div>

                                                </div>

                                                <div class="d-flex justify-content-between border-top mt-5 pt-10">
                                                    <div class="mr-2">
                                                        <button type="button" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-prev">Précédent</button>
                                                    </div>
                                                    <div>
                                                        <button type="button" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-submit">Mettre à jour </button>
                                                        <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-next">Suivant</button>
                                                    </div>
                                                </div>

                                                <div></div><div></div><div></div><div></div><div></div></form>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>


                    <br /><br /><br /><br />

                </div>
            </div>
        </div>



        <Footer />

    </div>



}