import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SignIn from './SignIn';

import SignUp from './SignUp';
import ForgotPasword from './ForgotPassword';
import jsCookie from 'js-cookie';
import General from './General';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";


const FormContent = () => {
    const { t } = useTranslation();

    


    const languages = [
        {
            code: 'fr',
            name: 'Français',
            country_code: 'fr'
        },
        {
            code: 'en',
            name: 'Anglais',
            country_code: 'gb'
        }
    ]




    const currentLanguageCode = jsCookie.get('i18next') || 'fr';
    const currentLanguage = languages.find(l => l.code === currentLanguageCode);




    return (
        <div>
            <div class="d-flex flex-column flex-root">

                <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                 
                    <General />
                    <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                        <div className='row' style={{ marginTop: "2em", marginBottom: '2em', paddingLeft: "5em", paddingRight: "5em" }}>

                            <div className='col text-right'>
                                <div class="btn-group dropright">
                                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className='fa fa-globe'></span> <b>{t("change_language_text")}</b></button>
                                    <div className="dropdown-menu " >
                                        {languages.map(({ code, name, country_code }) => (
                                            <a key={country_code} class="dropdown-item switch-locale" data-locale={code} >
                                                <button className='btn' onClick={() => i18n.changeLanguage(code)}>
                                                    <span className={'flag-icon flag-icon-' + country_code + ' mx-2'}></span>
                                                    {name}
                                                </button>
                                            </a>

                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <div class="login-form login-signin">
                                <SignIn />
                            </div>
                            <div class="login-form login-signup">
                                <SignUp />
                            </div>
                            <div class="login-form login-forgot">
                                <ForgotPasword />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    )
}


export default FormContent