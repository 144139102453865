import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";
import { useHistory } from 'react-router-dom';

import MUIDataTable from "mui-datatables";

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Avatar } from '@material-ui/core';
import { fontSize } from '@mui/system';


const muiCache = createCache({
	key: "mui-datatables",
	prepend: true
});


const SectionListUsers = () => {
	const { t } = useTranslation();


	let history = useHistory();

	const languages = [
		{
			code: 'fr',
			name: 'Français',
			country_code: 'fr'
		},
		{
			code: 'en',
			name: 'Anglais',
			country_code: 'gb'
		}
	]




	const currentLanguageCode = jsCookie.get('i18next') || 'fr';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);



	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

	const [responsive, setResponsive] = useState("vertical");
	const [tableBodyHeight, setTableBodyHeight] = useState("100%");
	const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
	const [searchBtn, setSearchBtn] = useState(true);
	const [downloadBtn, setDownloadBtn] = useState(true);
	const [printBtn, setPrintBtn] = useState(true);
	const [viewColumnBtn, setViewColumnBtn] = useState(true);
	const [filterBtn, setFilterBtn] = useState(true);



	const columns = [
		{
			name: "profileimage",
			label: "Profil",
			options: {
				customBodyRender: () => {
					return (
						<Link to="/personnalinfo" data-toggle="tab" data-target="#tabconfiguration" role="tab" >
						<span class="svg-icon svg-icon-primary svg-icon-4x"><svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"/>
        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
    </g>
</svg></span>
						</Link>

					)
				}
			}
		},
		{
			name: "Login",
			options: { filterOptions: { fullWidth: true } }
		},

		{
			name: "Nom",
			options: { filterOptions: { fullWidth: true } }
		},


		{
			name: "Postnom",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "Prenom",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "Sexe",
			options: { filterOptions: { fullWidth: true } }
		},

		{
			name: "Fonction",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "Agence",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "Contact ",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "status",
			label: "Status",
			options: {
				customBodyRender: () => {
					return (
						<span class="label label-xl label-inline label-light-danger">Inactif</span>
					)
				}
			}
		},
	
		
		{
			name: "",
			options: {
				filter: true,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Link to="/personnalinfo" data-toggle="tab" data-target="#tabconfiguration" role="tab" >

							<span class="svg-icon svg-icon-md">
								<svg xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24"></rect>
										<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) ">
										</path>
										<rect fill="#000000" opacity="0.3" x="5" y="20" rx="1"></rect>
									</g>
								</svg>
							</span>
						</Link>
					);
				}
			}
		},
		
	];

	const options = {
		search: searchBtn,
		download: downloadBtn,
		print: printBtn,
		viewColumns: viewColumnBtn,
		filter: filterBtn,
		filterType: "dropdown",
		responsive,
		tableBodyHeight,
		tableBodyMaxHeight,
		textLabels: {
			body: {
				noMatch: "Aucun enrégistrement trouvé pour cette récherche ",
				toolTip: "Trier",
				columnHeaderTooltip: column => `Trier par ${column.label}`
			},
			pagination: {
				next: "Page suivante",
				previous: "Page précédente",
				rowsPerPage: "Ligne par par page:",
				displayRows: "de",
			},
			toolbar: {
				search: "Réchercher",
				downloadCsv: "Télécharger en CSV",
				print: "Imprimer",
				viewColumns: "Visualiser la colonne",
				filterTable: "Filtrer le tableau",
			},
			filter: {
				all: "Tout",
				title: "FILTRES",
				reset: "REINITIALISER",
			},
			viewColumns: {
				title: "Afficher les colonnes",
				titleAria: "Afficher/Disparaitre les colonnes",
			},
			selectedRows: {
				text: "Ligne(s) sélectionnée(s)",
				delete: "Supprimer",
				deleteAria: "Supprimer le(s) ligne(s) sélectionnée(s)",
			},
		},

		onTableChange: (action, state) => {
			console.log(action);
			console.dir(state);
		}
	};

	const data = [

		[
			"",
			"kibaks",
			"KIBAKWETO",
			"AKARIGO",
			"Landry",
			"M",

			"Agent",
			"PLACE COMMERCIALE",
		
			"landrykibakweto123@gmail.com",
			"",
			""
		
		],
		[
			"",
			"kibaks",
			"KIBAKWETO",
			"AKARIGO",
			"Landry",
			"M",

			"Agent",
			"PLACE COMMERCIALE",
		
			"landrykibakweto123@gmail.com",
			"",
			""
		
		],
		[
			"",
			"kibaks",
			"KIBAKWETO",
			"AKARIGO",
			"Landry",
			"M",

			"Agent",
			"PLACE COMMERCIALE",
		
			"landrykibakweto123@gmail.com",
			"",
			""
		
		],
		[
			"",
			"kibaks",
			"KIBAKWETO",
			"AKARIGO",
			"Landry",
			"M",

			"Agent",
			"PLACE COMMERCIALE",
		
			"landrykibakweto123@gmail.com",
			"",
			""
		
		],
		[
			"",
			"kibaks",
			"KIBAKWETO",
			"AKARIGO",
			"Landry",
			"M",

			"Agent",
			"PLACE COMMERCIALE",
		
			"landrykibakweto123@gmail.com",
			"",
			""
		
		],
		
		

	];



	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect((e) => {
		if (isLoaded) {

			setIsPageLoaded(true);
		}
	}, [isLoaded]);


	let getMuiTheme = () => createTheme({
		components: {

			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						//backgroundColor: "#FF0000", 
						fontSize: 14,
						innerWidth: 300,


						fullWidth: true

					}
				}
			},
			MUIDataTableHead: {
				styleOverrides: {
					root: {
						//backgroundColor: "#FF0000", 
						fontSize: 14,
						innerWidth: 300,


						fullWidth: true

					}
				}
			}
		}
	})




	return (
		<div>

			<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div class="d-flex flex-column-fluid">
					<div class="container">
						<div class="row"><br />
							<h5 class="text-dark font-weight-bold my-1 mr-5">Gestion des utilisateurs </h5>

							<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">

								<li class="breadcrumb-item text-muted">
									<Link class="text-muted" to="/users" data-toggle="tab" data-target="#tabconfiguration" role="tab">Menu général</Link>
								</li>
								<li class="breadcrumb-item text-muted">
									<Link to="/userslist" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Liste des utilisateurs </Link>
								</li>

							</ul>
						</div>
						<div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
							<div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

								<div class="d-flex align-items-center flex-wrap mr-2">

									<h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Utilisateurs</h5>

									<div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>



								</div>

								<div class="d-flex align-items-center">


									<Link to="/adduser" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2">Ajouter un nouvel utilisateur</Link>

								</div>

							</div>
						</div>

						<CacheProvider value={muiCache}>
							<ThemeProvider theme={getMuiTheme()}>
								<MUIDataTable title={"Liste des utilisateurs"} data={data} columns={columns} options={options} />
							</ThemeProvider>
						</CacheProvider>

						<br /><br />

					</div>
				</div>
			</div>

		</div >

	)
}


export default SectionListUsers