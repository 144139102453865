import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";
import { useHistory } from 'react-router-dom';

import { useLocation } from 'react-router-dom'





const ModalAdd = () => {
	const { t } = useTranslation();


	let history = useHistory();

	const languages = [
		{
			code: 'fr',
			name: 'Français',
			country_code: 'fr'
		},
		{
			code: 'en',
			name: 'Anglais',
			country_code: 'gb'
		}
	]




	const currentLanguageCode = jsCookie.get('i18next') || 'fr';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);



	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps


	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect((e) => {
		if (isLoaded) {

			//history.push("/users")
			setIsPageLoaded(true);
		}
	}, [isLoaded]);

	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");




	return (
		<div>
			<div class="modal fade" id="permissionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">Ajout d'une permission</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<i aria-hidden="true" class="ki ki-close"></i>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group">
								<label>Désignation
									<span class="text-danger">*</span></label>
								<input type="email" class="form-control" placeholder="Enter un rôle" />
							</div>
							<div class="form-group row align-items-center">
																						<label class="col-xl-6 col-lg-6 col-form-label font-weight-bold text-left text-lg-right">Activer / Désactiver la permission </label>
																						<div class="col-lg-9 col-xl-6">
																							<span class="switch switch-sm">
																								<label>
																									<input type="checkbox" checked="checked" name="email_notification_1" />
																									<span></span>
																								</label>
																							</span>
																						</div>
																					</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fermer</button>
							<button type="button" class="btn btn-primary font-weight-bold" data-dismiss="modal">Enrégistrer</button>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}


export default ModalAdd