import authReducers from "./authReducers";

import actuReducer from "./actuReducers";
import {combineReducers} from 'redux'; 
import { Provider } from "react-redux";

const rootReducers = combineReducers({
    auth: null, 
    actuProject:null
})

export default rootReducers