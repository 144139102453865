import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Panel from '../dashboard/Panel';



const Footer = () => {
	const { t } = useTranslation();




	return (
		<div>
	<div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
						
						<div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
							
							<div class="text-dark order-2 order-md-1">
								<span class="text-muted font-weight-bold mr-2">© 2022</span>
								<a href="../../../keenthemes.com/metronic/index.html" target="_blank" class="text-dark-75 text-hover-primary">Matrix – Software by Matrix Technologies</a>
							</div>
							
							
							<div class="nav nav-dark order-1 order-md-2">
									<a href="../../../keenthemes.com/metronic/index.html" target="_blank" class="nav-link pl-3 pr-0">Nous contacter</a>
							</div>
						
						</div>
						
					</div>
                    <Panel />
		</div>
	)
}


export default Footer

