



import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SignIn from './SignIn';

import SignUp from './SignUp';
import ForgotPasword from './ForgotPassword';
import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";


const General = () => {
    const { t } = useTranslation();


    const languages = [
        {
            code: 'fr',
            name: 'Français',
            country_code: 'fr'
        },
        {
            code: 'en',
            name: 'Anglais',
            country_code: 'gb'
        }
    ]




    const currentLanguageCode = jsCookie.get('i18next') || 'fr';
    const currentLanguage = languages.find(l => l.code === currentLanguageCode);




    return (
        <div>
                   <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 bg" style={{'height' :'100%'}} >
                        <div class="d-flex flex-row-fluid flex-column justify-content-between">
                          
                         
                            <div class="flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 class="font-size-h1 mb-5 text-white">{t('title_img_login')}</h3>
                            </div>
                          
                            <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                <div class="opacity-70 font-weight-bold text-white">© Matrix – Software by <a href='https://matrixtechnologies.cd'>Matrix Technologies</a></div>
                                <div class="d-flex">

                                    <a href="#" class="text-white ml-10">Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
    )
}


export default General