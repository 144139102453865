//
import Store from '../../../../store/Store'
//

import React, { useState, useEffect } from 'react';

import jsCookie from 'js-cookie';
import i18n from "i18next";
import swal from 'sweetalert'

import Header from '../../../general/Header';
import Footer from '../../../general/Footer';
//
import MUIDataTable from "mui-datatables"
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Add, List, Edit, Delete } from '@material-ui/icons';

import { Link } from 'react-router-dom';


import ScriptImport from '../../../layout/ScriptImport';


const muiCache = createCache({
	key: "mui-datatables",
	prepend: true
});


let getMuiTheme = () => createTheme({
	components: {

		MUIDataTableBodyCell: {
			styleOverrides: {
				root: {
					//backgroundColor: "#FF0000", 
					fontSize: 14,
					innerWidth: 300,


					fullWidth: true

				}
			}
		},
		MUIDataTableHead: {
			styleOverrides: {
				root: {
					//backgroundColor: "#FF0000", 
					fontSize: 14,
					innerWidth: 300,


					fullWidth: true

				}
			}
		}
	}
})





//récupérer les agences 
let agences = Store.ajax("GET", 'https://jsonplaceholder.typicode.com/users')

//function
export default function Agences() {


	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

	const [responsive, setResponsive] = useState("vertical");
	const [tableBodyHeight, setTableBodyHeight] = useState("100%");
	const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
	const [searchBtn, setSearchBtn] = useState(true);
	const [downloadBtn, setDownloadBtn] = useState(true);
	const [printBtn, setPrintBtn] = useState(true);
	const [viewColumnBtn, setViewColumnBtn] = useState(true);
	const [filterBtn, setFilterBtn] = useState(true);



	const columns = [
		{
			name: "",
			options: {
				filter: true,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							<span class="svg-icon svg-icon-primary svg-icon-4x"><svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
								<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<rect x="0" y="0" width="30" height="30" />
									<path d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z" fill="#000000" />
								</g>
							</svg></span>
						</div>
					);
				}
			}
		},


		{
			name: "Désignation ",
			options: { filterOptions: { fullWidth: true } }
		},

		{
			name: "Ville",
			options: { filterOptions: { fullWidth: true } }
		},


		{
			name: "Chef d'agence",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "Adresse",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "Heure d'ouverture",
			options: { filterOptions: { fullWidth: true } }
		},
		{
			name: "Heure de fermeture",
			options: { filterOptions: { fullWidth: true } }
		},

		{
			name: "status",
			label: "Status",
			options: {
				customBodyRender: () => {
					return (
						<span class="label label-xl label-inline label-light-danger">Inactif</span>
					)
				}
			}
		},

		{
			name: "",
			options: {
				filter: true,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							<Link to="/edit" class="btn btn-sm btn-clean btn-icon mr-2" onClick={() => {
							

							}}>

								<span class="svg-icon svg-icon-md">
									<svg xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24" version="1.1">
										<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
											<rect x="0" y="0" width="24" height="24"></rect>
											<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) ">
											</path>
											<rect fill="#000000" opacity="0.3" x="5" y="20" rx="1"></rect>
										</g>
									</svg>
								</span>
							</Link>
						</div>
					);
				}
			}
		},

	];

	const options = {
		search: searchBtn,
		download: downloadBtn,
		print: printBtn,
		viewColumns: viewColumnBtn,
		filter: filterBtn,
		filterType: "dropdown",
		responsive,
		tableBodyHeight,
		tableBodyMaxHeight,
		textLabels: {
			body: {
				noMatch: "Aucun enrégistrement trouvé pour cette récherche ",
				toolTip: "Trier",
				columnHeaderTooltip: column => `Trier par ${column.label}`
			},
			pagination: {
				next: "Page suivante",
				previous: "Page précédente",
				rowsPerPage: "Ligne par par page:",
				displayRows: "de",
			},
			toolbar: {
				search: "Réchercher",
				downloadCsv: "Télécharger en CSV",
				print: "Imprimer",
				viewColumns: "Visualiser la colonne",
				filterTable: "Filtrer le tableau",
			},
			filter: {
				all: "Tout",
				title: "FILTRES",
				reset: "REINITIALISER",
			},
			viewColumns: {
				title: "Afficher les colonnes",
				titleAria: "Afficher/Disparaitre les colonnes",
			},
			selectedRows: {
				text: "Ligne(s) sélectionnée(s)",
				delete: "Supprimer",
				deleteAria: "Supprimer le(s) ligne(s) sélectionnée(s)",
			},
		},

		onTableChange: (action, state) => {
			console.log(action);
			console.dir(state);
		}
	};

	const data = [

		[

			"PLACE COMMERCIALE",
			"KINSHASA",
			"KIBAKWETO AKARIGO",

			"PLACE COMMERCIALE LOCAL 8",
			"08h00",
			"16h00",
			"",


		]

	];

	let getMuiTheme = () => createTheme({
		components: {

			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						//backgroundColor: "#FF0000", 
						fontSize: 14,
						innerWidth: 300,


						fullWidth: true

					}
				}
			},
			MUIDataTableHead: {
				styleOverrides: {
					root: {
						//backgroundColor: "#FF0000", 
						fontSize: 14,
						innerWidth: 300,


						fullWidth: true

					}
				}
			}
		}
	})

	useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect((e) => {
        if (isLoaded) {
			ScriptImport("metronic/js/scripts.bundle15aa.js?v=7.2.2");

            setIsPageLoaded(true);
        }
    }, [isLoaded]);


	function EditClick(e) {
		//e.preventDefault()
		swal({
			title: "Êtes-vous sûr?",
			text: "Voulez-vous vraiment Modifier cet élément",
			icon: "warning",
			boutons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					return true
				} else {
					return false
				}
			});
	}



	function DeleteClick(e) {
		//e.preventDefault()
		swal({
			title: "Êtes-vous sûr?",
			text: "Voulez-vous vraiment Supprimer cet élément",
			icon: "warning",
			boutons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					return true
				} else {
					return false
				}
			});

	}

	return <div>

		<Header />

		<div>

			<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div class="d-flex flex-column-fluid">
					<div class="container">
						<div class="row"><br />
							<h5 class="text-dark font-weight-bold my-1 mr-5">Gestion des utilisateurs </h5>

							<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">

								<li class="breadcrumb-item text-muted">
									<Link class="text-muted" to="/users" data-toggle="tab" data-target="#tabconfiguration" role="tab">Menu général</Link>
								</li>
								<li class="breadcrumb-item text-muted">
									<Link to="/userslist" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Liste des agences  </Link>
								</li>

							</ul>
						</div>
						<div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
							<div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

								<div class="d-flex align-items-center flex-wrap mr-2">

									<h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Agences </h5>

									<div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>



								</div>

								<div class="d-flex align-items-center">


									<Link to="/add_agence" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2">Ajouter une nouvelle agence</Link>

								</div>

							</div>
						</div>
						<CacheProvider value={muiCache}>
							<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							title={"La liste des agences"}
							columns={columns}
							data={data}
							options={options}

						/>
						</ThemeProvider>
						</CacheProvider>


						<br /><br /><br /><br /><br /><br /><br /><br />

					</div>
				</div>
			</div>

		</div >



		<Footer />

	</div >

}