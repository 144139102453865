
import React, { Component } from 'react';




import { connect } from 'react-redux'


import ScriptImport from '../layout/ScriptImport';

import FormContent from './FormContent';
import TextJsInteraction from './TextJsInteraction';

import $ from 'jquery'; 
import OptContent from './OptContent';

import RemoveScript from '../layout/RemoveSrc';







//
class Opt extends Component {

    constructor(props){
        super(props); 
       
    }


    componentDidMount() {
        {

         
            window.addEventListener('load', this.handleLoad);

        }


    }
    componentWillUnmount() {


        {
           /*  if (document.getElementsByClassName("script_import")) {
                 RemoveScript("js/jquery-3.6.0.min.js");
                 RemoveScript("js/myscript.js");
                 RemoveScript("metronic/plugins/global/plugins.bundle15aa.js?v=7.2.2");
                 RemoveScript("metronic/plugins/custom/prismj•s/prismjs.bundle15aa.js?v=7.2.2");
                 RemoveScript("metronic/js/scripts.bundle15aa.js?v=7.2.2");
 
                 RemoveScript("metronic/js/pages/widgets15aa.js");
             }
             window.removeEventListener('load', this.handleLoad)*/
 
 
         }
     }

    componentDidUpdate() {
        {



        }
    }
   

    render() {
      

        return (
            <div>
                  <OptContent />
            </div>
          
            

        );
    }


}

const mapStateToProps = (state) => {
    return {
        actu: " "
    }
}

export default connect(mapStateToProps)(Opt);