
import React, { useEffect, useState, Component } from 'react';


import Header from '../../../general/Header';

import Footer from '../../../general/Footer';
import { Helmet } from 'react-helmet';

import ScriptImport from '../../../layout/ScriptImport';
import RemoveScript from '../../../layout/RemoveSrc';

import SectionMenuAdd from './SectionMenuAdd'; 

import { useHistory } from 'react-router-dom';

import $ from "jquery";


class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = { show: true };
        this.handleLoad = this.handleLoad.bind(this);
        const { navigation } = this.props;
        this.navigation = navigation;




    }

    componentDidMount() {
        
        {

          /* ScriptImport("metronic/plugins/global/plugins.bundle15aa.js?v=7.2.2");
              
             ScriptImport("metronic/js/pages/widgets15aa.js");*/
             ScriptImport("metronic/plugins/custom/prismjs/prismjs.bundle15aa.js?v=7.2.2");
             ScriptImport("metronic/js/scripts.bundle15aa.js?v=7.2.2");

             ScriptImport("metronic/js/pages/custom/user/add-user15aa.js?v=7.2.2")

             ScriptImport("metronic/js/pages/crud/forms/widgets/bootstrap-datepicker15aa.js?v=7.2.2")
             ScriptImport("metronic/js/pages/crud/forms/widgets/input-mask15aa.js?v=7.2.2")

                      
          //  if (!document.getElementsByClassName("script_import")) {
           // ScriptImport("metronic/js/pages/widgets15aa.js");
                
               // ScriptImport("metronic/js/pages/widgets15aa.js"); 
            //}
          
           // ScriptImport("metronic/js/pages/widgets15aa.js?v=7.2.2")
        }


    }

    componentDidUpdate() {
        {
   
           
        }
    }
    componentWillUnmount() {


        {
        
            window.removeEventListener('load', this.handleLoad)


        }
    }

    handleLoad(e) {
      //  console.log(this.props)
      

    }

    render() {

        return (
            <div>
                <Header />
                <SectionMenuAdd />
                <Footer />



            </div>



        );
    }


}



export default AddUser;