import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import jsCookie from 'js-cookie';
import $ from "jquery";
import jqueryI18next from 'jquery-i18next';

import i18n from "i18next";
import { useHistory } from 'react-router-dom';

import { useLocation } from 'react-router-dom'; 

import General from '../General';





const SectionPermission = () => {
	const { t } = useTranslation();


	let history = useHistory();

	const languages = [
		{
			code: 'fr',
			name: 'Français',
			country_code: 'fr'
		},
		{
			code: 'en',
			name: 'Anglais',
			country_code: 'gb'
		}
	]




	const currentLanguageCode = jsCookie.get('i18next') || 'fr';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);



	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps


	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect((e) => {
		if (isLoaded) {

			//history.push("/users")
			setIsPageLoaded(true);
		}
	}, [isLoaded]);

	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");




	return (
		<div>

			<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div class="d-flex flex-column-fluid">
					<div class="container">
						<div class="row"><br />
							<h5 class="text-dark font-weight-bold my-1 mr-5">Gestion des utilisateurs</h5>

							<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
								<li class="breadcrumb-item text-muted">
									<Link class="text-muted" to="/users" data-toggle="tab" data-target="#tabconfiguration" role="tab">Menu général</Link>
								</li>
								<li class="breadcrumb-item text-muted">
									<Link to="/userslist" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted">Liste des utilisateurs </Link>
								</li>

								<li class="breadcrumb-item text-muted">
									<Link to="/adduser" data-toggle="tab" data-target="#tabconfiguration" role="tab" class="text-muted" >Profil de l'utilisateur</Link>
								</li>

							</ul>
						</div>
						<div class="content d-flex flex-column flex-column-fluid" id="kt_content">

							<div class="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
								<div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

									<div class="d-flex align-items-center flex-wrap mr-1">

										<button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
											<span></span>
										</button>

										<div class="d-flex align-items-baseline flex-wrap mr-5">

											<h5 class="text-dark font-weight-bold my-1 mr-5">Profil de l'utilisateur </h5>

											

										</div>

									</div>

									<div class="d-flex align-items-center">

										

									</div>

								</div>
							</div>

							<div class="d-flex flex-column-fluid">

								<div class="container">

									<div class="d-flex flex-row">

										<div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
											<General/>

										
										</div>

										<div class="d-flex flex-column-fluid">
							
							<div class="container">
								
								<div class="d-flex flex-row">
								
									
								
									<div class="flex-row-fluid ml-lg-8">
										
										<div class="card card-custom">
											
											<div class="card-header py-3">
												<div class="card-title align-items-start flex-column">
													<h3 class="card-label font-weight-bolder text-dark">Permissions</h3>
													<span class="text-muted font-weight-bold font-size-sm mt-1">Change your email settings</span>
												</div>
												<div class="card-toolbar">
													<button type="reset" class="btn btn-success mr-2">Mettre à jour</button>
													<button type="reset" class="btn btn-secondary">Annuler</button>
												</div>
											</div>
											
											<form class="form">
												<div class="card-body">
													<div class="row">
														<label class="col-xl-3"></label>
														<div class="col-lg-9 col-xl-6">
															<h5 class="font-weight-bold mb-6">Gestion des utilisateurs:</h5>
														</div>
													</div>
													<div class="form-group row align-items-center">
														<label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Email Notification</label>
														<div class="col-lg-9 col-xl-6">
															<span class="switch switch-sm">
																<label>
																	<input type="checkbox" checked="checked" name="email_notification_1"/>
																	<span></span>
																</label>
															</span>
														</div>
													</div>
													<div class="form-group row align-items-center">
														<label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Send Copy To Personal Email</label>
														<div class="col-lg-9 col-xl-6">
															<span class="switch switch-sm">
																<label>
																	<input type="checkbox" name="email_notification_2"/>
																	<span></span>
																</label>
															</span>
														</div>
													</div>
													<div class="separator separator-dashed my-10"></div>
													<div class="row">
														<label class="col-xl-3"></label>
														<div class="col-lg-9 col-xl-6">
															<h5 class="font-weight-bold mb-6">Gestion des clients:</h5>
														</div>
													</div>
													<div class="form-group row">
														<label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">When To Email</label>
														<div class="col-lg-9 col-xl-6">
															<div class="checkbox-list">
																<label class="checkbox">
																<input type="checkbox"/>
																<span></span>You have new notifications</label>
																<label class="checkbox">
																<input type="checkbox"/>
																<span></span>You're sent a direct message</label>
																<label class="checkbox">
																<input type="checkbox" checked="checked"/>
																<span></span>Someone adds you as a connection</label>
															</div>
														</div>
													</div>
													<div class="form-group row">
														<label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">When To Escalate Emails</label>
														<div class="col-lg-9 col-xl-6">
															<div class="checkbox-list">
																<label class="checkbox checkbox-primary">
																<input type="checkbox"/>
																<span></span>Upon new order</label>
																<label class="checkbox checkbox-primary">
																<input type="checkbox"/>
																<span></span>New membership approval</label>
																<label class="checkbox checkbox-primary">
																<input type="checkbox" checked="checked"/>
																<span></span>Member registration</label>
															</div>
														</div>
													</div>
													<div class="separator separator-dashed my-10"></div>
													<div class="row">
														<label class="col-xl-3"></label>
														<div class="col-lg-9 col-xl-6">
															<h5 class="font-weight-bold mb-6">Updates From Keenthemes:</h5>
														</div>
													</div>
													<div class="form-group row">
														<label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Email You With</label>
														<div class="col-lg-9 col-xl-6">
															<div class="checkbox-list">
																<label class="checkbox">
																<input type="checkbox"/>
																<span></span>News about Keenthemes products and feature updates</label>
																<label class="checkbox">
																<input type="checkbox"/>
																<span></span>Tips on getting more out of Keen</label>
																<label class="checkbox">
																<input type="checkbox" checked="checked"/>
																<span></span>Things you missed since you last logged into Keen</label>
																<label class="checkbox">
																<input type="checkbox" checked="checked"/>
																<span></span>News about Metronic on partner products and other services</label>
																<label class="checkbox">
																<input type="checkbox" checked="checked"/>
																<span></span>Tips on Metronic business products</label>
															</div>
														</div>
													</div>
												</div>
											</form>
											
										</div>
										
									</div>
									
								</div>
								
							</div>
							
						</div>
									</div>

								</div>

							</div>

						</div>


						<br /><br /><br /><br /><br /><br /><br /><br />

					</div>
				</div>
			</div>

		</div>

	)
}


export default SectionPermission